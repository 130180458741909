import { __awaiter } from "tslib";
import { getSponsoredWidgetsByDeviceMetadata, getSponsoredWidgetsByWidgetId, } from 'pathfinder/api/sponsoredWidgets/sponsoredWidget';
import { getMockedSponsoredLogos } from 'pathfinder/api/sponsoredWidgets/sponsoredLogo';
import { chooseSponsoredWidget } from 'pathfinder/utils/sponsoredWidgets/chooseSponsoredWidget';
import { chooseSponsoredWidgetState } from 'pathfinder/utils/sponsoredWidgets/chooseSponsoredWidgetState';
import { chooseImage } from 'pathfinder/utils/sponsoredWidgets/chooseImage';
export function getSponsoredLogos(isForced) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const widgetType = 'sponsored-logo';
        if (isForced) {
            return getMockedSponsoredLogos();
        }
        const sponsoredLogos = (_a = (yield getSponsoredWidgetsByWidgetId(widgetType))) !== null && _a !== void 0 ? _a : (yield getSponsoredWidgetsByDeviceMetadata(widgetType));
        if (!sponsoredLogos || !sponsoredLogos.length) {
            return null;
        }
        return sponsoredLogos;
    });
}
export function getSponsoredLogoBaseData(sponsoredLogos, isForced, device) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const widgetType = 'sponsored-logo';
        const size = { width: 36, height: 36 };
        const chosenSponsoredLogo = isForced
            ? sponsoredLogos[0]
            : chooseSponsoredWidget(sponsoredLogos !== null && sponsoredLogos !== void 0 ? sponsoredLogos : [], device, widgetType);
        if (!chosenSponsoredLogo) {
            return null;
        }
        const chosenSponsoredLogoState = chooseSponsoredWidgetState((_a = chosenSponsoredLogo.states) !== null && _a !== void 0 ? _a : [], device, widgetType, chosenSponsoredLogo === null || chosenSponsoredLogo === void 0 ? void 0 : chosenSponsoredLogo.id);
        if (!chosenSponsoredLogoState) {
            return null;
        }
        const image = chooseImage((_b = chosenSponsoredLogoState.images) !== null && _b !== void 0 ? _b : [], size, widgetType, chosenSponsoredLogo.id, chosenSponsoredLogoState.id);
        if (!image) {
            return null;
        }
        return Object.assign(Object.assign({}, chosenSponsoredLogoState), { sponsoredLogoId: chosenSponsoredLogo.id, imageUrl: image.url });
    });
}
