import { getRenderedModules } from 'pathfinder/store/appState';
import { ModuleType } from 'pathfinder/rules-engine/types';
export const checkWasQuizConsumptionModuleRendered = () => {
    const filter = (element) => [
        ModuleType.WikiQuizConsumptionModule,
        ModuleType.DiscussionQuizConsumptionModule,
        ModuleType.TriviaQuizConsumptionModule,
        ModuleType.FanhubQuizConsumptionModule,
    ].includes(element.config.type);
    return getRenderedModules().some(filter);
};
