import { ModuleType, InsertType } from '../types';
import _op from 'pathfinder/rules-engine/targetingRules/_operators';
import { DESKTOP_ANON_MEDIAWIKI_ARTICLE_RULESET, IS_NOT_TEST_WIKI_RULESET, } from 'pathfinder/rules-engine/targetingRules/_templates';
const targeting = _op.all(DESKTOP_ANON_MEDIAWIKI_ARTICLE_RULESET, IS_NOT_TEST_WIKI_RULESET);
const disabled = true;
const collapseToCModules = [
    {
        disabled,
        name: 'collapse-toc-module-variant-1',
        type: ModuleType.CollapseToC,
        insertConfig: { type: InsertType.Custom },
        expBucket: [],
        config: {},
        targeting,
    },
    {
        disabled,
        name: 'collapse-toc-module-variant-2',
        type: ModuleType.CollapseToC,
        insertConfig: { type: InsertType.Custom },
        expBucket: [],
        config: {},
        targeting,
    },
    {
        disabled,
        name: 'collapse-toc-module-control',
        type: ModuleType.CollapseToC,
        insertConfig: { type: InsertType.Custom },
        expBucket: [],
        config: {},
        targeting,
    },
];
export default collapseToCModules;
