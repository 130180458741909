import { __awaiter } from "tslib";
import { getMobileDeviceName } from 'utils/isMobile';
export var UserDeviceType;
(function (UserDeviceType) {
    UserDeviceType["Desktop"] = "desktop";
    UserDeviceType["Mobile"] = "mobile";
})(UserDeviceType || (UserDeviceType = {}));
export var UserDeviceOrientation;
(function (UserDeviceOrientation) {
    UserDeviceOrientation["Landscape"] = "landscape";
    UserDeviceOrientation["Portrait"] = "portrait";
})(UserDeviceOrientation || (UserDeviceOrientation = {}));
function getCurrentDeviceType() {
    return getMobileDeviceName() === false ? UserDeviceType.Desktop : UserDeviceType.Mobile;
}
function getCurrentDeviceOrientation() {
    var _a, _b;
    const h = (_a = window === null || window === void 0 ? void 0 : window.innerHeight) !== null && _a !== void 0 ? _a : 0;
    const w = (_b = window === null || window === void 0 ? void 0 : window.innerWidth) !== null && _b !== void 0 ? _b : 0;
    // squares are considered landscape
    return w >= h ? UserDeviceOrientation.Landscape : UserDeviceOrientation.Portrait;
}
export const checkUserDeviceRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { deviceType, deviceOrientation } = rule;
    let result = true;
    if (deviceType) {
        result && (result = deviceType === getCurrentDeviceType());
    }
    if (deviceOrientation) {
        result && (result = deviceOrientation === getCurrentDeviceOrientation());
    }
    return result;
});
export const validateUserDeviceRule = (rule) => {
    const { deviceType, deviceOrientation } = rule;
    // deviceType needs to be correct
    if (deviceType && !Object.values(UserDeviceType).includes(deviceType)) {
        return false;
    }
    // deviceOrientation needs to be correct
    if (deviceOrientation && !Object.values(UserDeviceOrientation).includes(deviceOrientation)) {
        return false;
    }
    return true;
};
