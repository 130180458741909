import flatten from 'lodash/flatten';
import { TargetingOperator } from '../types';
/** Negates a SINGLE rule */
function notRule(rules) {
    // die if there's nothing to do
    if (Array.isArray(rules) && rules.length === 0) {
        return [];
    }
    const result = [];
    result.push(rules);
    result.push([TargetingOperator.Not]);
    return flatten(result);
}
function joinWithOperatorFactory(operator) {
    return (...rules) => {
        // die if there's nothing to do
        if (rules.length < 2) {
            return flatten(rules);
        }
        const result = [];
        // weave the operator
        rules.forEach((r, i) => {
            result.push(r);
            if (i > 0) {
                result.push([operator]);
            }
        });
        return flatten(result);
    };
}
/** Joins multiple rules with `TargetingOperator.Or` */
const anyRule = joinWithOperatorFactory(TargetingOperator.Or);
/** Joins multiple rules with `TargetingOperator.And` */
const allRules = joinWithOperatorFactory(TargetingOperator.And);
// export only unified API
export default {
    /** not(A) - Negation for rule or ruleset */
    not: notRule,
    /** any(A, B, ...) - Either one of the rules must be true; connect the rules with OR */
    any: anyRule,
    /** all(A, B, ...) - All the rules must be true; connect the rules with AND */
    all: allRules,
};
