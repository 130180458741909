import consoleLoggerFactory from '../../utils/consoleLoggerFactory';
function getStyles(bgColor) {
    return ['color: #fff', 'padding: 2px 4px', 'border-radius: 2px', `background-color: ${bgColor}`];
}
export function buildModuleLoggerFunctions(moduleConfig) {
    return {
        /** General important information (the fewer=the better) */
        info: consoleLoggerFactory(`Pathfinder Module | ${moduleConfig.name}`, 'info', getStyles('#334')),
        /** Debug info (only visible with debug param) */
        debug: consoleLoggerFactory(`Pathfinder Module | ${moduleConfig.name}`, 'debug', getStyles('#550')),
        /** Errors (always visible) */
        error: consoleLoggerFactory(`Pathfinder Module | ${moduleConfig.name}`, 'error', getStyles('#800')),
    };
}
const defaultLoggerFunctions = {
    /** General important information (the fewer=the better) */
    info: consoleLoggerFactory('Pathfinder', 'info', getStyles('#334')),
    /** Debug info (only visible with debug param) */
    debug: consoleLoggerFactory('Pathfinder', 'debug', getStyles('#550')),
    /** Errors (always visible) */
    error: consoleLoggerFactory('Pathfinder', 'error', getStyles('#800')),
};
export default defaultLoggerFunctions;
