import { __awaiter } from "tslib";
import log from 'pathfinder/utils/log';
import { callFandomService } from 'pathfinder/utils/api';
import { localStorageHelper } from 'pathfinder/utils/localStorageHelper';
import { getDeviceMetadata } from 'pathfinder/utils/sponsoredWidgets/DeviceMetadata';
let sponsoredWidgetsService = null;
export function getSponsoredWidgetService() {
    var _a, _b, _c, _d;
    if (!sponsoredWidgetsService) {
        const device = getDeviceMetadata();
        const wikiCategories = (_a = window === null || window === void 0 ? void 0 : window.mw) === null || _a === void 0 ? void 0 : _a.config.get('wikiCategories');
        const params = new URLSearchParams([
            ['wId', device.pageContext.pId],
            ...(device.dateIsForced ? [['dT', device.dateTime]] : []),
            ...(device.pageContext.pVar ? [['s', device.pageContext.pVar]] : []),
            ...(((_d = (_c = (_b = device.pageContext) === null || _b === void 0 ? void 0 : _b.adTags) === null || _c === void 0 ? void 0 : _c.gnre) === null || _d === void 0 ? void 0 : _d.length) ? device.pageContext.adTags.gnre.map((value) => ['g', value]) : []),
            ...((wikiCategories === null || wikiCategories === void 0 ? void 0 : wikiCategories.length) ? wikiCategories.map((value) => ['c', value]) : []),
        ]).toString();
        const path = `campaign-provider/v2/widgets${params ? `?${params}` : ''}`;
        sponsoredWidgetsService = new SponsoredWidgetsService(path);
    }
    return sponsoredWidgetsService;
}
export class SponsoredWidgetsService {
    constructor(path) {
        this.path = path;
        this.storage = localStorageHelper(this.path);
    }
    getWidgets() {
        if (!this.widgetsPromise) {
            this.widgetsPromise = this.getFromCacheOrFetchSponsoredWidgets();
        }
        return this.widgetsPromise;
    }
    getFromCacheOrFetchSponsoredWidgets() {
        return __awaiter(this, void 0, void 0, function* () {
            let widgetsData = this.storage.get();
            if (!widgetsData) {
                widgetsData = yield fetchFromFandomService('SponsoredWidgets', this.path);
                if (typeof widgetsData === 'string') {
                    this.storage.set(widgetsData);
                }
                else {
                    this.storage.set('null');
                }
            }
            if (widgetsData) {
                try {
                    return (yield JSON.parse(widgetsData));
                }
                catch (e) {
                    return null;
                }
            }
            return null;
        });
    }
}
export function fetchFromFandomService(logPrefix, path) {
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            response = yield callFandomService(path);
        }
        catch (e) {
            log.error(`${logPrefix}: fetching error`, e);
            return null;
        }
        if (!response.ok || response.status === 204) {
            log.debug(`${logPrefix}: no consumable data`, response);
            return null;
        }
        return response.text();
    });
}
