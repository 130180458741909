import { __awaiter } from "tslib";
export const checkAtLeastDomNodesRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    return document.querySelectorAll(rule.selector).length >= rule.min;
});
export const validateAtLeastDomNodesRule = (rule) => {
    const { selector, min } = rule;
    // selector needs to be a non-empty string
    if (typeof selector !== 'string' || !selector.length) {
        return false;
    }
    // min needs to be a positive number
    if (typeof min !== 'number' || min <= 0) {
        return false;
    }
    return true;
};
