import { __awaiter } from "tslib";
import { getPageContextFromWindow } from '@fandom/context';
import log from '../../utils/log';
export const checkCurrentContextRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const context = getPageContextFromWindow();
    const { property, value, adTags } = rule;
    try {
        if (typeof property === 'string') {
            const wantedValue = value.toLowerCase();
            if (property !== 'adTags') {
                const contextValue = context[property];
                return (contextValue === null || contextValue === void 0 ? void 0 : contextValue.toLowerCase()) === wantedValue;
            }
            else {
                // special case for checking only values of adtags
                const contextAdTags = (_a = context === null || context === void 0 ? void 0 : context.adTags) !== null && _a !== void 0 ? _a : {};
                const adTagsValues = Object.values(contextAdTags)
                    .reduce((sum, current) => sum.concat(current), [])
                    .map((v) => v.toLowerCase());
                return adTagsValues.some((v) => v === wantedValue);
            }
        }
        if (typeof adTags !== 'undefined') {
            const contextAdTags = (_b = context === null || context === void 0 ? void 0 : context.adTags) !== null && _b !== void 0 ? _b : {};
            let result = true;
            // check existence of all key/values
            for (const [key, adTagValues] of Object.entries(adTags)) {
                result && (result = adTagValues.every((v) => {
                    const t = contextAdTags[key];
                    return t.includes(v);
                }));
            }
            return result;
        }
    }
    catch (e) {
        log.error('RulesEngine/currentContext: Exception', e, rule);
    }
    return false;
});
export const validateCurrentContextRule = (rule) => {
    const { property, value } = rule;
    // property needs to be a non-empty string
    if (typeof property !== 'string' || !property.length) {
        return false;
    }
    // value needs to be a non-empty string (if present)
    if (value && (typeof value !== 'string' || !value.length)) {
        return false;
    }
    return true;
};
