import { InsertType, ModuleType } from '../types';
import { ARTICLES_RULE, IS_NOT_EDITOR_RULESET } from 'pathfinder/rules-engine/targetingRules/_templates';
import _operators from 'pathfinder/rules-engine/targetingRules/_operators';
const marketingNotificationsModules = [
    {
        disabled: false,
        name: 'marketing-notifications',
        type: ModuleType.MarketingNotifications,
        insertConfig: { type: InsertType.Custom },
        config: {
            impressionName: ModuleType.MarketingNotifications,
        },
        targeting: _operators.all(ARTICLES_RULE, IS_NOT_EDITOR_RULESET),
    },
];
export default marketingNotificationsModules;
