import { hasAnyTestingQueryParam } from 'utils/env';
import trackerFactoryDataLayer from 'fandom-frontend-ready/tracking';
export function buildModuleTrackingFunction(module) {
    return buildTrackingFunctions(module.type, module.name);
}
export function trackRootPathfinder(options = {}) {
    return buildTrackingFunctions('PF', 'Root')(options);
}
// current actions = 'loaded', 'impression', 'click'
// Generic function to track events across PF modules
// see https://docs.google.com/document/d/1IFs-UyNhnUuBFn34syW0mSO_z_T1WA-jiHzW8_3_ZAc
export function trackExperimentsModule(module, options = {}) {
    return buildModuleTrackingFunction(module)(options);
}
export function buildTrackingFunctions(moduleType, moduleName) {
    const isDebuggingLike = hasAnyTestingQueryParam();
    const category = `pf-experiments${isDebuggingLike ? '-dev' : ''}: ${moduleType}`;
    return trackerFactoryDataLayer({ category, experimentVariant: moduleName, isDebug: isDebuggingLike });
}
