import { __awaiter } from "tslib";
import { Product, getPageContextFromWindow } from '@fandom/context';
import log from 'pathfinder/utils/log';
export const checkMwConfigRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { property, value } = rule;
    const ctx = getPageContextFromWindow();
    // only available for MediaWiki platform
    if (ctx.product !== Product.MEDIAWIKI) {
        log.debug('RuleEngine/checkMwConfig: Unsupported product');
        return false;
    }
    try {
        if (!window.mw || !window.mw.config) {
            log.error('RuleEngine/checkMwConfig: mw not initialized');
            return false;
        }
        const mwConfig = window.mw.config;
        const mwValue = mwConfig.get(property);
        if (mwValue === undefined) {
            log.error(`RuleEngine/checkMwConfig: property [${property}] does not exist in mw`);
            return false;
        }
        if (Array.isArray(value)) {
            if (typeof mwValue === 'number' || typeof mwValue === 'string') {
                return value.some((v) => v === mwValue);
            }
            /* eslint-disable */
            // @ts-expect-error ignore wrong typing cast
            return mwValue.some((v) => value.includes(v));
            /* eslint-enable */
        }
        if (typeof value === 'object' && value) {
            // check existence of all key/values
            return Object.keys(mwValue).some((key) => mwValue[key] === value[key]);
        }
        return mwValue === value;
    }
    catch (e) {
        log.error('RuleEngine/checkMwConfig: Exception', e, rule);
    }
    return false;
});
export const validateMwConfigRule = (rule) => {
    const { property, value } = rule;
    // property needs to be a non-empty string
    if (typeof property !== 'string' || !property.length) {
        return false;
    }
    // value needs to be present
    if (!value) {
        return false;
    }
    return true;
};
