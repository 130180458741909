import log from 'pathfinder/utils/log';
import { getPermaForcedCookie, permaforceCookie, setPermaForcedCookie } from 'pathfinder/utils/permaforceCookie';
import getValueFromQuery from 'utils/getValueFromQuery';
/** Wrap for easier testing */
export default function getForcedModuleName() {
    const forcedName = getValueFromQuery('ss_pathfinder_forced');
    const forcedNameFromCookie = getPermaForcedCookie();
    if (forcedName) {
        log.info('RulesEngine: Forcing module', forcedName);
        if (forcedNameFromCookie) {
            log.info(`RulesEngine: Ignoring permanently forced module (from "${permaforceCookie}" cookie)`, forcedNameFromCookie);
        }
        return forcedName;
    }
    const permaforcedName = getValueFromQuery('ss_pathfinder_permaforced');
    if (permaforcedName) {
        log.info('RulesEngine: Permanently forcing module (expires in 30 days)', permaforcedName);
        setPermaForcedCookie(permaforcedName);
        return permaforcedName;
    }
    if (forcedNameFromCookie) {
        log.info(`RulesEngine: Permanently forcing module (from "${permaforceCookie}" cookie)`, forcedNameFromCookie);
        return forcedNameFromCookie;
    }
}
