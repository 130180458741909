// This is a single way to load SilverSurfer as global dependency
// Do not import anything in here (except type definitions and very small pieces of the code)
import { isGlobalDebugMode } from '../utils/env';
import waitForEvent from '../utils/waitForEvent';
const startTime = Date.now();
let elapsed = -1;
/**
 * Alternative to listening on `SILVER_SURFER_EVENT.READY` event, resolves when the Silver Surfer is ready (or timed out)
 */
const waitForSilverSurfer = waitForEvent({
    eventCheck: () => { var _a; return (_a = window === null || window === void 0 ? void 0 : window.SilverSurferSDK) === null || _a === void 0 ? void 0 : _a.isReady(); },
    intervalTime: 10,
    maxWaitTime: 60000,
    onReject: () => {
        // can't load in given MAX_WAIT_TIME
        console.error('silver-surfer-loaded-timeout');
    },
    onResolve: () => {
        // calculate `elapsed`, but make sure it's only once
        if (elapsed < 0) {
            elapsed = Date.now() - startTime;
        }
        // send log only on `ss_debug=true`
        if (isGlobalDebugMode()) {
            console.debug('silver-surfer-loaded', elapsed);
        }
    },
});
export default waitForSilverSurfer;
