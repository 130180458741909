import { __awaiter } from "tslib";
import waitForEvent from './waitForEvent';
export const MOBILE_SKINS = ['fandommobile', 'wikiamobile', 'mobileve', 'mobileskin'];
export const DESKTOP_SKINS = ['fandomdesktop', 'oasis', 'hydra', 'hydradark', 'exvius', 'minerva', 'vector'];
// lazy load window.mw
const waitForMwConfig = waitForEvent({
    eventCheck: () => { var _a; return !!((_a = window === null || window === void 0 ? void 0 : window.mw) === null || _a === void 0 ? void 0 : _a.config); },
    intervalTime: 10,
    maxWaitTime: 5000,
    onReject: () => null,
    onResolve: () => window.mw.config,
});
let mwConfig = null;
function getMwConfig() {
    return __awaiter(this, void 0, void 0, function* () {
        if (!mwConfig) {
            mwConfig = yield waitForMwConfig();
        }
        return (mwConfig !== null && mwConfig !== void 0 ? mwConfig : {});
    });
}
/**
 * @deprecated Use `getContext().current.productVariant`
 */
export const getSkin = () => __awaiter(void 0, void 0, void 0, function* () { var _a, _b; return (_b = (_a = (yield getMwConfig())) === null || _a === void 0 ? void 0 : _a.get('skin')) !== null && _b !== void 0 ? _b : null; });
/**
 * @deprecated
 */
export const isMobileSkin = () => __awaiter(void 0, void 0, void 0, function* () { return typeof MOBILE_SKINS[yield getSkin()] !== 'undefined'; });
export const isThemeDark = () => __awaiter(void 0, void 0, void 0, function* () { var _c, _d; return (_d = (_c = (yield getMwConfig())) === null || _c === void 0 ? void 0 : _c.get('isDarkTheme')) !== null && _d !== void 0 ? _d : false; });
