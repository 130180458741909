import { __awaiter } from "tslib";
import PromiseAllSettled from 'utils/PromiseAllSettled';
/** Helper function to get all resolved promise from collection of promises */
export default function getResolvedArray(collection) {
    return __awaiter(this, void 0, void 0, function* () {
        return ((yield PromiseAllSettled(collection))
            // grab the value from resolved promises
            .filter((prom) => prom.status === 'fulfilled')
            // ts is incorrectly showing "Property 'value' does not exist on type '{ status: string; reason: any; }'", we filtered it in line above, so let's force typecase.
            .map((prom) => prom === null || prom === void 0 ? void 0 : prom.value)
            // filter only truthy values
            .filter(Boolean));
    });
}
