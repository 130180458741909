import _op from '../targetingRules/_operators';
import { DESKTOP_RULE, MW_DESKTOP_SKIN_RULE, USER_LOGGED_IN_RULE, } from 'pathfinder/rules-engine/targetingRules/_templates';
import { InsertType, ModuleType, TargetingType } from '../types';
const SPECIAL_PAGE_RULE = {
    type: TargetingType.Url,
    pathname: 'Special:AdminDashboard',
};
const notificationCMSModules = [
    {
        disabled: false,
        name: 'notification-cms',
        type: ModuleType.NotificationCMS,
        insertConfig: { type: InsertType.Custom },
        config: {},
        targeting: _op.all(DESKTOP_RULE, MW_DESKTOP_SKIN_RULE, USER_LOGGED_IN_RULE, SPECIAL_PAGE_RULE),
    },
];
export default notificationCMSModules;
