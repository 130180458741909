import { __awaiter } from "tslib";
import getValueFromQuery from 'utils/getValueFromQuery';
import { QUERY_PARAM_NAMES } from 'utils/env';
export const checkTestOnlyOnBranchRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { branch } = rule;
    if (branch === 'canary') {
        return getValueFromQuery(QUERY_PARAM_NAMES.CANARY) === 'true';
    }
    return getValueFromQuery(QUERY_PARAM_NAMES.BRANCH) === branch;
});
export const validateTestOnlyOnBranchRule = (rule) => {
    const { branch } = rule;
    // branch needs to be a non-empty string
    if (typeof branch !== 'string' || !branch.length) {
        return false;
    }
    return true;
};
