export const pushCurrentQuizQuestionDataToWindow = (questionData, questionIndex) => {
    if (!questionData) {
        window.getCurrentQuizQuestion = () => undefined;
        return;
    }
    const correctAnswer = questionData.answers.find((answer) => answer.isCorrect);
    if (correctAnswer) {
        window.getCurrentQuizQuestion = () => ({
            questionId: questionData.id,
            questionPosition: questionIndex,
            quizAnswerPosition: questionData.answers.indexOf(correctAnswer),
            quizAnswerId: correctAnswer.id,
            isGenai: questionData.isGenai,
        });
    }
};
