import { __awaiter } from "tslib";
import log from 'pathfinder/utils/log';
export const checkFandomQuizzesRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { property, value } = rule;
    try {
        const quizzes = window.quizzes;
        if (!quizzes) {
            log.debug('RuleEngine/checkFandomQuizzes: window.quizzes not initialized');
            return false;
        }
        const fandomQuizzesValue = quizzes[property];
        if (fandomQuizzesValue === undefined) {
            log.error(`RuleEngine/checkFandomQuizzes: property [${property}] does not exist in quizzes object [${quizzes}]`);
            return false;
        }
        return fandomQuizzesValue === value;
    }
    catch (e) {
        log.error('RuleEngine/checkFandomQuizzes: Exception', e, rule);
    }
});
export const validateFandomQuizzesRule = (rule) => {
    const { property, value } = rule;
    // property needs to be a non-empty string
    if (typeof property !== 'string' || !property.length) {
        return false;
    }
    // value needs to be type of boolean
    if (typeof value !== 'boolean') {
        return false;
    }
    return true;
};
