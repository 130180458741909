import { __awaiter } from "tslib";
import { getGeo } from '@fandom/context';
function getUserCountry() {
    var _a, _b;
    const country = (_b = (_a = getGeo()) === null || _a === void 0 ? void 0 : _a.country) !== null && _b !== void 0 ? _b : 'us';
    return country.toLowerCase();
}
export const checkUserGeoRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const currentCountry = getUserCountry();
    const { country } = rule;
    return country.some((c) => c.toLowerCase() === currentCountry);
});
export const validateUserGeoRule = (rule) => {
    const { country } = rule;
    // country needs to be an array of strings
    if (!Array.isArray(country) || country.some((c) => typeof c !== 'string')) {
        return false;
    }
    return true;
};
