export function localStorageHelper(key, expiresInMinutes = 5) {
    const oneMinute = 60000;
    const ttlInMiliseonds = expiresInMinutes * oneMinute;
    const expired = (ttl) => (ttl ? ttl < Date.now() : true);
    const parseStoredValue = (storedValue) => {
        const json = JSON.parse(storedValue);
        return expired(json === null || json === void 0 ? void 0 : json.ttl) ? null : json.data;
    };
    const getObjectToStore = (data) => ({
        data,
        ttl: Date.now() + ttlInMiliseonds,
    });
    return {
        get: () => {
            try {
                const storedValue = localStorage.getItem(key);
                return !storedValue ? null : parseStoredValue(storedValue);
            }
            catch (_a) {
                console.warn(`Can't retrieve  ${key} from localStorage`);
                return null;
            }
        },
        set: (data) => {
            try {
                const objectToStore = getObjectToStore(data);
                localStorage.setItem(key, JSON.stringify(objectToStore));
            }
            catch (_a) {
                console.warn(`Can't write ${key} ${data} to localStorage`);
            }
        },
    };
}
