import log from 'pathfinder/utils/log';
import { checkSponsoredWidgetStateApplicability } from './checkSponsoredWidgetStateApplicability';
import { checkSponsoredWidgetStateValidity } from 'pathfinder/utils/sponsoredWidgets/checkSponsoredWidgetStateValidity';
export function chooseSponsoredWidgetState(states, device, widgetType, widgetId, checkStateValidity = undefined, checkStateApplicability = undefined) {
    states = [
        ...states
            .filter((value) => value.startDateTime !== null)
            .sort((a, b) => b.startDateTime.localeCompare(a.startDateTime)),
        ...states.filter((value) => value.startDateTime === null).sort((a, b) => a.id - b.id),
    ];
    const logPrefixWidget = `${widgetType}: widget(${widgetId}): `;
    for (const state of states) {
        const logPrefixState = logPrefixWidget + `state(${state.id}): `;
        const logInvalidity = (details) => log.error(logPrefixState + details);
        const stateValidity = checkSponsoredWidgetStateValidity(state);
        if (typeof stateValidity === 'string') {
            logInvalidity(stateValidity);
            continue;
        }
        const customStateValidity = checkStateValidity ? checkStateValidity(state) : null;
        if (typeof customStateValidity === 'string') {
            logInvalidity(customStateValidity);
            continue;
        }
        const logInapplicability = (details) => log.debug(logPrefixState + details);
        const stateApplicability = checkSponsoredWidgetStateApplicability(state, device);
        if (typeof stateApplicability === 'string') {
            logInapplicability(stateApplicability);
            continue;
        }
        const customStateApplicability = checkStateApplicability ? checkStateApplicability(state, device) : null;
        if (typeof customStateApplicability === 'string') {
            logInapplicability(customStateApplicability);
            continue;
        }
        return state;
    }
    log.debug(logPrefixWidget + 'no applicable states');
    return null;
}
