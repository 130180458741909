import { __awaiter } from "tslib";
import log from 'pathfinder/utils/log';
/**
 * This rule validates these scenarios according to the number of last visited pages by the user:
 *
 * Check if an user was in a specific wiki within the last *n* pages visited.
 * Check if an user was in a wiki specifically targeted for [X], within the last *n* entries.
 * At least *n* pages are targeting a [X] property.
 *
 *
 * @param {VisitedPagesRule} rule
 * @returns true if the rule satifies its condition, false otherwise
 */
export const checkVisitedPagesRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const { lastVisitedPages, property, value, adTags, atLeast } = rule;
    try {
        const context = window.SilverSurferSDK.getContext().pages.slice(0, lastVisitedPages);
        let frequency = 0;
        for (const page of context) {
            if (typeof property === 'string') {
                if (property !== 'adTags') {
                    const contextValue = page[property];
                    const wantedValue = value.toLowerCase();
                    if ((contextValue === null || contextValue === void 0 ? void 0 : contextValue.toLowerCase()) === wantedValue) {
                        frequency++;
                    }
                }
                else {
                    // special case for checking only values of adtags
                    const wantedValue = value.toLowerCase();
                    const contextAdTags = (_a = page === null || page === void 0 ? void 0 : page.adTags) !== null && _a !== void 0 ? _a : {};
                    const adTagsValues = Object.values(contextAdTags)
                        .reduce((sum, current) => sum.concat(current), [])
                        .map((v) => v.toLowerCase());
                    return adTagsValues.some((v) => v === wantedValue);
                }
            }
            // you're probably looking for adTags
            if (typeof adTags !== 'undefined') {
                const contextAdTags = (_b = page === null || page === void 0 ? void 0 : page.adTags) !== null && _b !== void 0 ? _b : {};
                let valueFound = true;
                // check existence of all key/values
                for (const [key, adTagValues] of Object.entries(adTags)) {
                    valueFound && (valueFound = adTagValues.every((v) => {
                        const t = contextAdTags[key];
                        return t.includes(v);
                    }));
                }
                if (valueFound) {
                    frequency++;
                }
            }
        }
        return frequency >= atLeast;
    }
    catch (e) {
        log.error('RulesEngine/visitedPages: Exception', e, rule);
    }
    return false;
});
export const validateVisitedPagesRule = (rule) => {
    const { lastVisitedPages, property, value, atLeast } = rule;
    // lastVisitedPages needs to be a number
    if (typeof lastVisitedPages !== 'number') {
        return false;
    }
    // property needs to be a non-empty string
    if (typeof property !== 'string' || !property.length) {
        return false;
    }
    // value needs to be a non-empty string
    if (typeof value !== 'string' || !value.length) {
        return false;
    }
    // atLeast needs to be a number
    if (typeof atLeast !== 'number') {
        return false;
    }
    return true;
};
