const expandExpBucketRange = (range) => {
    const result = [];
    for (let i = 0; i < range.length; i++) {
        if (range[i].includes('-')) {
            const [start, end] = range[i].split('-').map((item) => Number(item));
            if (isNaN(start) || isNaN(end)) {
                console.error(`Invalid bucketing for exp_bucket: ${range[i]}`);
                return [];
            }
            for (let j = start; j <= end; j++) {
                result.push(j.toString());
            }
        }
        else {
            if (isNaN(Number(range[i]))) {
                console.error(`Invalid bucketing for exp_bucket: ${range[i]}`);
                return [];
            }
            result.push(range[i]);
        }
    }
    return [...new Set(result)];
};
export default expandExpBucketRange;
