import { getWikiaBeaconId, getExpBucket } from '@fandom/context';
import getForcedBuckets from 'pathfinder/utils/getForcedBuckets';
import expandExpBucketRange from 'pathfinder/rules-engine/helpers/expandExpBucketRange';
// New bucket logic
export function applyBucketsLogic(modules) {
    // Get all modules that do not have bucketing set in config - those will just be passed along
    const modulesWithNoBuckets = modules.filter((module) => !(module === null || module === void 0 ? void 0 : module.buckets) && !(module === null || module === void 0 ? void 0 : module.expBucket));
    // Get all modules that have `buckets` in config, get only the ones that do not have `expBucket` in config or it's empty, because expBucket has higher priority
    const moduleWithBeaconBucket = modules.filter((module) => { var _a; return ((module === null || module === void 0 ? void 0 : module.buckets) && ((_a = module === null || module === void 0 ? void 0 : module.expBucket) === null || _a === void 0 ? void 0 : _a.length) === 0) || ((module === null || module === void 0 ? void 0 : module.buckets) && !(module === null || module === void 0 ? void 0 : module.expBucket)); });
    // Get all modules that have `expBucket` in config
    const moduleWithExpBucket = modules.filter((module) => module === null || module === void 0 ? void 0 : module.expBucket);
    // check if there is a forced bucket
    const forcedBucket = getForcedBuckets();
    // Get user current bucket from 2 cookies
    let currentExpBucket = getExpBucket();
    let currentBeaconBucket = getBeaconBucket();
    if (typeof forcedBucket !== 'undefined' && (forcedBucket === null || forcedBucket === void 0 ? void 0 : forcedBucket.length) > 0) {
        currentExpBucket = forcedBucket;
        currentBeaconBucket = forcedBucket;
    }
    const modulesInCurrentBucket = [];
    // check if modules with buckets belong to current bucket
    if (moduleWithBeaconBucket.length > 0) {
        moduleWithBeaconBucket.filter((module) => module.buckets.some((bucket) => bucket.includes(currentBeaconBucket) && modulesInCurrentBucket.push(module)));
    }
    if (moduleWithExpBucket.length > 0) {
        moduleWithExpBucket.filter((module) => expandExpBucketRange(module.expBucket).some((bucket) => bucket === currentExpBucket && modulesInCurrentBucket.push(module)));
    }
    // return all modules that do not have `buckets` in config + modules that have `buckets` in config and belong to current bucket
    return [...modulesWithNoBuckets, ...modulesInCurrentBucket];
}
export const DEFAULT_BUCKET_INDEX = 0;
export const ALLOWED_BUCKET_CHAR = /[0-9a-zA-Z_-]/;
export const ALLOWED_BUCKETS_STRING = /[0-9a-zA-Z_-]+/;
export const CONTROL_BUCKETS_CHAR = /[a-d]/;
export const ALLOWED_EXP_BUCKET = /(0|[1-9][0-9]?)/;
export function getBeaconBucket(index = DEFAULT_BUCKET_INDEX) {
    // Use beacon as bucketing tool
    // https://github.com/Wikia/wikia-vcl/blob/master/wikia.com/service-beacon-deliver.vcl#L7
    const beacon = getWikiaBeaconId();
    return beacon === null || beacon === void 0 ? void 0 : beacon[index];
}
export var BucketType;
(function (BucketType) {
    BucketType["Valid"] = "Valid";
    BucketType["Invalid"] = "Invalid";
    BucketType["Control"] = "Control";
})(BucketType || (BucketType = {}));
export function validateBucket(buckets) {
    if (buckets.map((bucket) => !ALLOWED_BUCKET_CHAR.test(bucket)).some(Boolean)) {
        return BucketType.Invalid;
    }
    if (buckets.map((bucket) => CONTROL_BUCKETS_CHAR.test(bucket)).some(Boolean)) {
        return BucketType.Control;
    }
    return BucketType.Valid;
}
/** Get list of overlapping buckets */
/** Note: We don't use overlapping checks  */
export function checkForBucketOverlap(buckets) {
    // no buckets, nothing to do
    if (!(buckets === null || buckets === void 0 ? void 0 : buckets.length)) {
        return [];
    }
    // merge all strings, sort, return if we have two or more symbols next to each other, but trim them to 1st character each
    const matches = buckets
        .filter(String)
        .map((bucket) => bucket.split(''))
        .reduce((acc, val) => acc.concat(val), [])
        .sort()
        .join('')
        .match(/(.)\1+/g);
    return matches ? matches.map((match) => match[0]) : [];
}
