import { __awaiter } from "tslib";
export const isTestWikiRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const isTestWiki = ((_b = (_a = window.mw) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.get('isTestWiki')) || false;
    return rule.value === isTestWiki;
});
export const validateIsTestWikiRule = (rule) => {
    const { value } = rule;
    // selector needs to be a non-empty string
    if (typeof value !== 'boolean') {
        return false;
    }
    return true;
};
