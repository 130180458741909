// disable cookie eslint rule for this file, as it is a valid usage of cookies
/* eslint-disable regex/invalid */
import { isLocalHost } from 'utils/env';
import { getCookieByName } from 'utils/getCookieByName';
import log from './log';
export const permaforceCookie = 'pf_fm';
export function getPermaForcedCookie() {
    return getCookieByName(permaforceCookie);
}
export function removePermaForcedCookie() {
    document.cookie = `${permaforceCookie}=; path=/; domain=${location.hostname}; expires=${new Date(0).toUTCString()}`;
    if (!getCookieByName(permaforceCookie)) {
        log.info("Permanent forced module's cookie was deleted successfully");
    }
}
export function setPermaForcedCookie(moduleName) {
    const maxAge = 86400 * 30; // 30 days
    const domain = isLocalHost() ? '' : 'domain=.fandom.com';
    document.cookie = `${permaforceCookie}=${moduleName}; max-age=${maxAge}; ${domain}; path=/`;
}
