import _operators from '../targetingRules/_operators';
import { ActionType } from 'sdk/models/Action/types';
import { CATEGORY_TV_RULE, MOBILE_ANON_MEDIAWIKI_RULESET, NAMESPACE_0_RULE } from '../targetingRules/_templates';
import { TargetingType, ModuleType, InsertType } from '../types';
const inWikiEmailModules = [
    {
        name: 'in-wiki-email-in-context',
        type: ModuleType.InWikiEmail,
        insertConfig: { type: InsertType.UcpInContext },
        config: {},
        /**
         * - only on mw
         * - only on ordinary wikia articles
         * - only for anons
         * - only on mobile
         * - NOT subscribed cookie
         * - without a submit action for silver surfer
         */
        targeting: _operators.all(MOBILE_ANON_MEDIAWIKI_RULESET, CATEGORY_TV_RULE, NAMESPACE_0_RULE, _operators.not({
            type: TargetingType.SilverSurferAction,
            actionType: ActionType.SUBMIT,
            property: 'name',
            value: 'exit-signup',
        })),
    },
];
export default inWikiEmailModules;
