import { __awaiter } from "tslib";
import { Product, getPageContextFromWindow } from '@fandom/context';
import log from 'pathfinder/utils/log';
export const checkFandomContextRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { context, property, value } = rule;
    const ctx = getPageContextFromWindow();
    // only available for MediaWiki platform
    if (ctx.product !== Product.MEDIAWIKI) {
        log.debug('RuleEngine/checkFandomContext: Unsupported product');
        return false;
    }
    try {
        if (!window.fandomContext || !window.fandomContext[context]) {
            log.error('RuleEngine/checkFandomContext: fandomContext not initialized');
            return false;
        }
        const fandomContextValue = window.fandomContext[context][property];
        if (fandomContextValue === undefined) {
            log.error(`RuleEngine/checkFandomContext: property [${property}] does not exist in fandomContext [${context}]`);
            return false;
        }
        if (Array.isArray(fandomContextValue)) {
            if (typeof value === 'number' || typeof value === 'string') {
                return fandomContextValue.some((v) => v === value);
            }
            /* eslint-disable */
            // @ts-expect-error ignore wrong typing cast
            return value.some((v) => fandomContextValue.includes(v));
            /* eslint-enable */
        }
        if (Array.isArray(value)) {
            if (typeof fandomContextValue === 'number' || typeof fandomContextValue === 'string') {
                return value.some((v) => v === fandomContextValue);
            }
            /* eslint-disable */
            // @ts-expect-error ignore wrong typing cast
            return fandomContextValue.some((v) => value.includes(v));
            /* eslint-enable */
        }
        if (typeof value === 'object' && value) {
            // check existence of all key/values
            return Object.keys(fandomContextValue).some((key) => fandomContextValue[key] === value[key]);
        }
        return fandomContextValue === value;
    }
    catch (e) {
        log.error('RuleEngine/checkFandomContext: Exception', e, rule);
    }
    return false;
});
export const validateFandomContextRule = (rule) => {
    const { context, property, value } = rule;
    // context needs to be either 'site' or 'page'
    if (typeof context !== 'string' || !['site', 'page'].includes(context)) {
        return false;
    }
    // property needs to be a non-empty string
    if (typeof property !== 'string' || !property.length) {
        return false;
    }
    // value needs to be present
    if (!value) {
        return false;
    }
    return true;
};
