import { MOBILE_SKINS } from './getWikiInfo';
export const isSmallScreen = () => window === null || window === void 0 ? void 0 : window.matchMedia('screen and (max-width: 727px)').matches;
export const getMobileDeviceName = () => {
    // @ts-expect-error We know what we're doing here...
    const userAgent = String((navigator === null || navigator === void 0 ? void 0 : navigator.userAgent) || (navigator === null || navigator === void 0 ? void 0 : navigator.vendor) || (window === null || window === void 0 ? void 0 : window.opera) || '');
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'windows-phone';
    }
    if (/android/i.test(userAgent)) {
        return 'android';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // Package upgrade let to a typescript error not recognizing MSStream. Using @ts-expect-error to bypass check for just that line.
    // @ts-expect-error: Unreachable code error
    if (/ipad|iphone|ipod/i.test(userAgent) && !window.MSStream) {
        return 'ios';
    }
    return false;
};
export const getForcedMobile = () => {
    var _a, _b;
    const searchParams = new URLSearchParams(window === null || window === void 0 ? void 0 : window.location.search);
    const isMobileApp = (_b = (_a = document === null || document === void 0 ? void 0 : document.querySelector('html')) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.contains('mobile-app');
    const useSkin = searchParams.has('useskin') ? searchParams.get('useskin') : '';
    const isMobileSkin = MOBILE_SKINS.some((skin) => skin === useSkin.toLowerCase());
    return isMobileApp || isMobileSkin;
};
export default () => getForcedMobile() || getMobileDeviceName() || isSmallScreen();
