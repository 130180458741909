import { __awaiter } from "tslib";
/**
 * Lazy loader for getWebPushModule
 *
 * NOTE: If the module is always loaded (ie. doesn't use TargetingRules), it may be better to skip
 * the lazy loader and just import the module directly. This will reduce the number of chunks.
 * Instead, the lazy loading inside getWebPushModule should be used.
 */
export const getWebPushModule = (moduleConfig) => __awaiter(void 0, void 0, void 0, function* () {
    const module = yield import(/* webpackChunkName: "web-push-module-loader" */ './getWebPushModuleMethods');
    return module.getWebPushModuleMethods(moduleConfig);
});
