import { __awaiter } from "tslib";
import log from '../../utils/log';
function toArray(s) {
    if (Array.isArray(s)) {
        return s;
    }
    if (typeof s === 'string') {
        return [s];
    }
}
function anyMatches(list, value) {
    return list.some((v) => {
        const re = new RegExp(v, 'i');
        return re.test(value);
    });
}
export const checkUrlRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { location } = window;
    try {
        const href = toArray(rule === null || rule === void 0 ? void 0 : rule.href);
        const host = toArray(rule === null || rule === void 0 ? void 0 : rule.host);
        const pathname = toArray(rule === null || rule === void 0 ? void 0 : rule.pathname);
        const search = toArray(rule === null || rule === void 0 ? void 0 : rule.search);
        if (href) {
            if (!anyMatches(href, location.href)) {
                return false;
            }
        }
        if (host) {
            if (!anyMatches(host, location.host)) {
                return false;
            }
        }
        if (pathname) {
            if (!anyMatches(pathname, location.pathname)) {
                return false;
            }
        }
        if (search) {
            if (!anyMatches(search, location.search)) {
                return false;
            }
        }
        return true;
    }
    catch (e) {
        log.error('RuleEngine/checkUrlRule: Exception', e, rule);
    }
    return false;
});
const stringOrArrayOfStrings = (x) => typeof x === 'undefined' ||
    typeof x === 'string' ||
    (Array.isArray(x) && x.every((element) => typeof element === 'string'));
export const validateUrlRule = (rule) => {
    const { href, host, pathname, search } = rule;
    // all fields need to be correct
    return (stringOrArrayOfStrings(href) &&
        stringOrArrayOfStrings(host) &&
        stringOrArrayOfStrings(pathname) &&
        stringOrArrayOfStrings(search));
};
