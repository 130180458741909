import log from 'pathfinder/utils/log';
import { checkSponsoredWidgetApplicability } from './checkSponsoredWidgetApplicability';
import { checkSponsoredWidgetValidity } from './checkSponsoredWidgetValidity';
import { getSponsoredWidgetQueryId } from './getSponsoredWidgetQueryId';
export function chooseSponsoredWidget(widgets, device, widgetType, checkWidgetValidity = undefined, checkWidgetApplicability = undefined) {
    widgets = widgets.sort((a, b) => a.id - b.id);
    const logPrefixType = `${widgetType}: `;
    const swPreviewWidgetQueryId = getSponsoredWidgetQueryId(widgetType);
    for (const widget of widgets) {
        if (swPreviewWidgetQueryId === widget.id.toString()) {
            return widget;
        }
        const logPrefixWidget = logPrefixType + `widget(${widget.id}): `;
        const logInvalidity = (details) => log.error(logPrefixWidget + details);
        const widgetValidity = checkSponsoredWidgetValidity(widget);
        if (typeof widgetValidity === 'string') {
            logInvalidity(widgetValidity);
            continue;
        }
        const customWidgetValidity = checkWidgetValidity ? checkWidgetValidity(widget) : null;
        if (typeof customWidgetValidity === 'string') {
            logInvalidity(customWidgetValidity);
            continue;
        }
        const logInapplicability = (details) => log.debug(logPrefixWidget + details);
        const widgetApplicability = checkSponsoredWidgetApplicability(widget, device);
        if (typeof widgetApplicability === 'string') {
            logInapplicability(widgetApplicability);
            continue;
        }
        const customWidgetApplicability = checkWidgetApplicability ? checkWidgetApplicability(widget, device) : null;
        if (typeof customWidgetApplicability === 'string') {
            logInapplicability(customWidgetApplicability);
            continue;
        }
        return widget;
    }
    log.debug(logPrefixType + 'no applicable widgets');
    return null;
}
