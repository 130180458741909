import { __awaiter } from "tslib";
export const checkReferrerRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = document === null || document === void 0 ? void 0 : document.referrer) === null || _a === void 0 ? void 0 : _a.includes(rule.urlContains);
});
export const validateReferrerRule = (rule) => {
    const { urlContains } = rule;
    // urlContains needs to be a non-empty string
    if (typeof urlContains !== 'string' || !urlContains.length) {
        return false;
    }
    return true;
};
