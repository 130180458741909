import { __awaiter } from "tslib";
import { getUserBrowser } from 'pathfinder/utils/getUserBrowser';
export const checkBrowserRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const value = rule.value;
    if (Array.isArray(value)) {
        return value.includes(getUserBrowser());
    }
    return value === getUserBrowser();
});
export const validateBrowserRule = (rule) => {
    const value = rule.value;
    // value needs to be a non-null and not empty
    return !(value && !value.length);
};
