import { __awaiter } from "tslib";
import toLower from 'lodash/toLower';
export function getCurrentCategories() {
    // TODO: This is a hack because `window?.wgCategories` is unavailable in mobile view - for full feature release this needs to be fixed; also non-en is unsupported
    return Array.from(document.querySelectorAll('a[href^="/wiki/Category"]'))
        .map((s) => s.innerText)
        .map(toLower);
}
export const checkWikiCategory = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { any } = rule;
    const categories = getCurrentCategories();
    // match any categories
    if (Array.isArray(any) && any.length) {
        const anyRegExp = any.map((s) => new RegExp(s, 'i'));
        // calculate results for `any`
        const anyResults = anyRegExp.map((re) => categories.some((c) => re.test(c)));
        // pass true if any test passed, otherwise fail
        return anyResults.some((v) => v);
    }
    return false;
});
export const validateWikiCategoryRule = (rule) => {
    const { any } = rule;
    // any need to be an array of strings
    if (!Array.isArray(any) || any.some((v) => typeof v !== 'string')) {
        return false;
    }
    return true;
};
