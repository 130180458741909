import { checkPeriodCoverage } from './checkPeriodCoverage';
import { checkTargetingsCoverage } from './checkTargetingsCoverage';
export function checkSponsoredWidgetApplicability(widget, device) {
    if (!widget.active) {
        return 'not active';
    }
    const periodCoverage = checkPeriodCoverage(widget.startDateTime, widget.endDateTime, device.dateTime);
    if (typeof periodCoverage === 'string') {
        return periodCoverage;
    }
    const targetingsCoverage = checkTargetingsCoverage(widget, device);
    if (typeof targetingsCoverage === 'string') {
        return targetingsCoverage;
    }
    return null;
}
