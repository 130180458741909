import { __awaiter } from "tslib";
import { getToken } from '../../store/appState';
import { ActionType } from 'sdk/types';
import log from '../../utils/log';
export const checkSilverSurferActionRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { actionType, property, value } = rule;
    try {
        // get all actions of name
        const actions = yield window.SilverSurferSDK.getActions(getToken(), actionType);
        // found some actions, don't care about values
        if (property === undefined && (actions === null || actions === void 0 ? void 0 : actions.length) > 0) {
            return true;
        }
        // find by property, and just return a boolean
        return !!actions.find((a) => a[property] === value);
    }
    catch (e) {
        log.error('RuleEngine/checkSilverSurferActionRule: Exception', e, rule);
    }
    return false;
});
export const validateSilverSurferActionsRule = (rule) => {
    const { actionType, property, value } = rule;
    // actionType needs to be correct
    if (!Object.values(ActionType).includes(actionType)) {
        return false;
    }
    // property needs to be a non-empty string (if present)
    if (property && (typeof property !== 'string' || !property.length)) {
        return false;
    }
    // value needs to be a non-empty string (if present)
    if (value && (typeof value !== 'string' || !value.length)) {
        return false;
    }
    return true;
};
