import { checkWasQuizConsumptionModuleRendered } from 'pathfinder/modules/shared/QuizConsumption/utils/checkWasQuizConsumptionModuleRendered';
export const setPageHaveQuizBySelector = (selector) => {
    // wait for quiz renderers to finish rendering
    // (mutations and stuff takes time, so we cannot check this during dom rendering, we need to give browser some time)
    setTimeout(() => {
        setPageHaveQuiz(document.querySelectorAll(selector).length > 0);
    }, 1000);
};
export const unsetPageHaveQuiz = () => {
    setPageHaveQuiz(undefined);
};
export const setPageHaveQuiz = (value) => {
    console.log('PHQ=' + value);
    window.pageHaveQuiz = value;
};
export const initialisePageHaveQuizBasingOnState = () => {
    // there will be situations when QuizConsumption module is not loaded because of targeting (logged-in users),
    // therefore we should not no quiz zes were presented, in any other case we need to wait for the rendering to
    // determine was quiz shown to the visitor or not
    if (window.pageHaveQuiz !== undefined) {
        // value was already set, ignore (race condition when init is executed after value was set from components)
        return;
    }
    setPageHaveQuiz(!checkWasQuizConsumptionModuleRendered() ? false : undefined);
};
