import { getGeo } from '@fandom/context';
import { getSponsoredWidgetQueryDate } from 'pathfinder/utils/sponsoredWidgets/getSponsoredWidgetQueryDate';
export function getDeviceMetadata() {
    var _a;
    const forcedDate = getSponsoredWidgetQueryDate();
    return {
        dateTime: (forcedDate || new Date()).toISOString(),
        dateIsForced: forcedDate !== null,
        geo: (_a = getGeo().country) !== null && _a !== void 0 ? _a : '',
        pageContext: window.SilverSurferSDK.getContext().current.toTransport(),
    };
}
