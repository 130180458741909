import { FANDOM_ROOT_DOMAIN_RULE } from 'pathfinder/rules-engine/targetingRules/_templates';
import { ModuleType, InsertType } from '../types';
const WebPushModules = [
    {
        disabled: true,
        name: 'web-push',
        type: ModuleType.WebPush,
        insertConfig: { type: InsertType.Instant },
        config: {},
        targeting: [FANDOM_ROOT_DOMAIN_RULE],
    },
];
export default WebPushModules;
