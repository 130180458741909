import _op from '../targetingRules/_operators';
import { USER_LOGGED_IN_RULE } from '../targetingRules/_templates';
import { TargetingType, ModuleType, InsertType, InsertElementMethod } from '../types';
export var QuizCreationModuleType;
(function (QuizCreationModuleType) {
    QuizCreationModuleType["WithCatalog"] = "WithCatalog";
})(QuizCreationModuleType || (QuizCreationModuleType = {}));
const quizCatalogModuleConfig = {
    name: 'quiz-creation-with-catalog',
    type: ModuleType.QuizCreation,
    insertConfig: {
        type: InsertType.Element,
        selectors: [
            {
                selector: 'main',
                method: InsertElementMethod.Prepend,
            },
        ],
    },
    config: {
        type: QuizCreationModuleType.WithCatalog,
    },
    targeting: _op.all(USER_LOGGED_IN_RULE, {
        type: TargetingType.CurrentContext,
        property: 'product',
        value: 'dis',
    }, _op.any({
        type: TargetingType.CurrentContext,
        property: 'productId',
        value: '2750727', // trivia.fandom.com
    }, {
        type: TargetingType.CurrentContext,
        property: 'productId',
        value: '1081891', // keiko-test.fandom.com, for testing
    }), {
        type: TargetingType.CurrentContext,
        property: 'page',
        value: 'feed',
    }),
};
const quizCreationModules = [
    // regular module
    quizCatalogModuleConfig,
    Object.assign(Object.assign({}, quizCatalogModuleConfig), { disabled: true, name: 'quiz-creation-with-catalog-e2e', config: {
            type: QuizCreationModuleType.WithCatalog,
            fakeApis: true,
        } }),
];
export default quizCreationModules;
