import _operators from '../targetingRules/_operators';
import { ONLY_DEV_ENV_RULE, ON_DEBUG_SESSION_RULE } from '../targetingRules/_templates';
import { ModuleType, InsertType } from '../types';
const debugModules = [
    {
        name: 'debug-menu',
        type: ModuleType.DebugMenu,
        insertConfig: { type: InsertType.Instant },
        /**
         * available on any site with ss_debug
         * always available on localhost/dev
         */
        targeting: _operators.any(ON_DEBUG_SESSION_RULE, ONLY_DEV_ENV_RULE),
    },
];
export default debugModules;
