import { __awaiter } from "tslib";
import { getCountdownClocks } from 'pathfinder/modules/SponsoredWidgetsCountdownClockConsumptionModule/helpers/getCountdownClockBaseData';
export const getSponsoredWidgetsCountdownClockConsumptionModule = (moduleConfig) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const countdownClocks = yield getCountdownClocks((_b = (_a = moduleConfig === null || moduleConfig === void 0 ? void 0 : moduleConfig.internalState) === null || _a === void 0 ? void 0 : _a.forced) !== null && _b !== void 0 ? _b : false);
    if (!countdownClocks || !countdownClocks.length) {
        return;
    }
    const module = yield import(
    /* webpackChunkName: "SponsoredWidgetsSponsoredLogoConsumptionModule" */ './getSponsoredWidgetsCountdownClockConsumptionModuleMethods');
    return module.getSponsoredWidgetsCountdownClockConsumptionModuleMethods(Object.assign(Object.assign({}, moduleConfig), { config: Object.assign(Object.assign({}, moduleConfig.config), { countdownClocks }) }));
});
