import _operators from '../targetingRules/_operators';
import { DISCUSSION_RULE, ANON_MEDIAWIKI_RULESET, TRIVIA_RULE, MW_DESKTOP_SKIN_RULE, MW_MOBILE_SKIN_RULE, FANHUB_RULE, } from '../targetingRules/_templates';
import { TargetingType, ModuleType, InsertType } from '../types';
export var QuizConsumptionVariant;
(function (QuizConsumptionVariant) {
    QuizConsumptionVariant["Desktop"] = "after_toc_placement";
    QuizConsumptionVariant["Mobile"] = "after_infobox_placement";
})(QuizConsumptionVariant || (QuizConsumptionVariant = {}));
// window.mw.config.values.wgFandomQuizzesEnabled
const MW_CONFIG_FANDOM_QUIZZES_ENABLED = {
    type: TargetingType.MwConfig,
    property: 'wgFandomQuizzesEnabled',
    value: true,
};
// window.mw.config.values.wgFandomQuizzesCommunityQuizzesOnArticlesEnabled
const MW_CONFIG_FANDOM_QUIZZES_ON_ARTICLES_ENABLED = {
    type: TargetingType.MwConfig,
    property: 'wgFandomQuizzesCommunityQuizzesOnArticlesEnabled',
    value: true,
};
// window.quizzes.wgFandomQuizzesEnabled
const QUIZZES_CONFIG_FANDOM_QUIZZES_ENABLED = {
    type: TargetingType.FandomQuizzes,
    property: 'wgFandomQuizzesEnabled',
    value: true,
};
// window.quizzes.wgFandomQuizzesOnFepoEnabled
const FANDOM_QUIZZES_ON_FEPO_ENABLED = {
    type: TargetingType.FandomQuizzes,
    property: 'wgFandomQuizzesOnFepoEnabled',
    value: true,
};
const selectorTargeting = _operators.any({
    type: TargetingType.CurrentContext,
    property: 'page',
    value: 'article',
}, {
    type: TargetingType.DomNode,
    selector: '#mw-content-text [data-quiz-anchor]',
});
const triviaTargeting = _operators.any({
    type: TargetingType.CurrentContext,
    property: 'page',
    value: 'quiz-index',
}, {
    type: TargetingType.CurrentContext,
    property: 'page',
    value: 'quiz-page',
});
const quizConsumptionModules = [
    {
        name: 'wiki-quiz-consumption-module-desktop',
        type: ModuleType.WikiQuizConsumptionModule,
        insertConfig: { type: InsertType.Custom },
        config: {
            variant: QuizConsumptionVariant.Desktop,
        },
        targeting: _operators.all(MW_CONFIG_FANDOM_QUIZZES_ENABLED, MW_CONFIG_FANDOM_QUIZZES_ON_ARTICLES_ENABLED, ANON_MEDIAWIKI_RULESET, MW_DESKTOP_SKIN_RULE, selectorTargeting),
    },
    {
        name: 'wiki-quiz-consumption-module-mobile',
        type: ModuleType.WikiQuizConsumptionModule,
        insertConfig: { type: InsertType.Custom },
        config: {
            variant: QuizConsumptionVariant.Mobile,
        },
        targeting: _operators.all(MW_CONFIG_FANDOM_QUIZZES_ENABLED, MW_CONFIG_FANDOM_QUIZZES_ON_ARTICLES_ENABLED, ANON_MEDIAWIKI_RULESET, MW_MOBILE_SKIN_RULE, selectorTargeting),
    },
    {
        name: 'discussion-quiz-consumption-module',
        type: ModuleType.DiscussionQuizConsumptionModule,
        insertConfig: { type: InsertType.Custom },
        targeting: _operators.all(QUIZZES_CONFIG_FANDOM_QUIZZES_ENABLED, FANDOM_QUIZZES_ON_FEPO_ENABLED, DISCUSSION_RULE),
    },
    {
        name: 'trivia-quiz-consumption-module',
        type: ModuleType.TriviaQuizConsumptionModule,
        insertConfig: { type: InsertType.Custom },
        targeting: _operators.all(TRIVIA_RULE, triviaTargeting),
    },
    {
        name: 'fanhub-quiz-consumption-module',
        type: ModuleType.FanhubQuizConsumptionModule,
        insertConfig: { type: InsertType.Custom },
        targeting: _operators.all(FANHUB_RULE),
    },
];
export default quizConsumptionModules;
