import { __awaiter } from "tslib";
import log from '../utils/log';
import { getAllModules, getModuleMethodFunction } from './config';
import { checkTargetingRules } from './targetingEngine';
import { applyBucketsLogic, getBeaconBucket } from './testVariantsLogic';
import getForcedModuleName from './helpers/getForcedModuleName';
import getResolvedArray from 'utils/getResolvedArray';
import { getRenderedModules } from 'pathfinder/store/appState';
import extractModuleNames from 'pathfinder/rules-engine/helpers/extractModuleNames';
/** Convert config with `getModuleMethodFunction` to async function that can be easily called by `Promise.allSettled` */
export function getModuleWithMethods(config) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, _reject) => {
            try {
                const getModuleMethod = getModuleMethodFunction(config.type);
                getModuleMethod(config)
                    .then((result) => {
                    if (result) {
                        resolve(Object.assign({ config }, result));
                    }
                    else {
                        log.debug('RulesEngine: Module failed to provide methods (no result)', config);
                        resolve(false);
                    }
                })
                    .catch((error) => {
                    log.error('RulesEngine: Module failed to provide methods (exception from getModuleMethod)', config, error);
                    resolve(false);
                });
            }
            catch (error) {
                log.error('RulesEngine: Module failed to provide methods (exception getting getModuleMethod)', config, error);
                resolve(false);
            }
        });
    });
}
/** Filter the modules (allows them to run their API et al.) */
export function getEnabledModules() {
    return __awaiter(this, void 0, void 0, function* () {
        const logPrefix = `RulesEngine/getAvailableModules:`;
        log.debug(`${logPrefix} Checking available modules`);
        const renderedModuleNames = extractModuleNames(getRenderedModules());
        // get list of possible modules to load
        const moduleCandidates = yield getModuleCandidates(renderedModuleNames);
        // fail early for empty module array
        if (!moduleCandidates.length) {
            log.debug(`${logPrefix} No candidates`);
            return [];
        }
        // error message if module's forced but RE couldn't find it
        const forcedModuleName = getForcedModuleName();
        if (forcedModuleName &&
            !moduleCandidates.map((m) => m.name).includes(forcedModuleName) &&
            !renderedModuleNames[forcedModuleName]) {
            log.error(`${logPrefix} Cannot find forced module`, forcedModuleName);
        }
        // check which modules can be rendered - allow them to call their APIs, etc.
        // @ts-expect-error Ignore: Argument of type 'Promise<false | Module>[]' is not assignable to parameter of type 'Promise<Module>[]'.
        const modules = yield getResolvedArray(moduleCandidates.map(getModuleWithMethods));
        // return all available modules
        log.debug(`${logPrefix} Available modules`, modules);
        return modules;
    });
}
/** Returns all avialable modules */
export function getModuleCandidates(moduleNamesToOmit = []) {
    return __awaiter(this, void 0, void 0, function* () {
        const logPrefix = `RulesEngine/getModuleCandidates:`;
        const allModules = getAllModules();
        // fail early for empty module sets
        if (!allModules.length) {
            log.error(`${logPrefix} No modules`);
            return [];
        }
        log.debug(`${logPrefix} Modules`, allModules);
        // check if any module is forced
        const forcedModuleName = getForcedModuleName();
        let forcedModuleConfig;
        if (forcedModuleName) {
            forcedModuleConfig = allModules.find((c) => c.name === forcedModuleName);
        }
        // filter modules that have been explicitly disabled and aren't forced
        let enabledModules = allModules.filter((module) => !(module === null || module === void 0 ? void 0 : module.disabled));
        log.debug(`${logPrefix} Enabled modules`, enabledModules);
        if (moduleNamesToOmit.length) {
            enabledModules = enabledModules.filter((module) => !moduleNamesToOmit.includes(module.name));
            if (!enabledModules.length) {
                return [];
            }
        }
        // roll the dice for modules with limited rollout
        const modulesInCurrentTraffic = enabledModules.filter(limitTrafficFilter);
        log.debug(`${logPrefix} Enabled modules in current traffic`, modulesInCurrentTraffic);
        // filter modules by buckets
        const modulesInCurrentBucket = applyBucketsLogic(modulesInCurrentTraffic);
        log.debug(`${logPrefix} Modules with buckets applied`, modulesInCurrentBucket);
        // check targeting for all modules, discard modules that didn't pass the check
        const moduleCandidates = yield getResolvedArray(modulesInCurrentBucket.map(checkTargetingRules));
        log.debug(`${logPrefix} Module candidates`, moduleCandidates);
        // if there's forced module, remove it from `moduleCandidates`, mark it as forced and add it gain
        if (forcedModuleConfig && !moduleNamesToOmit.includes(forcedModuleName)) {
            log.info(`${logPrefix} Forced module, forcing it`, forcedModuleConfig);
            forcedModuleConfig.internalState.forced = true;
            // remove forced module from candidates
            const moduleCandidatesWithoutForced = moduleCandidates.filter((c) => c.name !== (forcedModuleConfig === null || forcedModuleConfig === void 0 ? void 0 : forcedModuleConfig.name));
            // add forced module to the beginning of the list
            moduleCandidatesWithoutForced.unshift(forcedModuleConfig);
            return moduleCandidatesWithoutForced;
        }
        return moduleCandidates;
    });
}
export function limitTrafficFilter(config) {
    const limitTraffic = config === null || config === void 0 ? void 0 : config.limitTraffic;
    if (typeof limitTraffic === 'undefined') {
        // no field, we're OK
        return true;
    }
    if (typeof limitTraffic === 'number') {
        // check if we can render module based on % of random traffic
        return Math.random() < limitTraffic;
    }
    if (typeof limitTraffic === 'string') {
        // check if we can render module based on buckets
        return limitTraffic.includes(getBeaconBucket());
    }
    if (Array.isArray(limitTraffic)) {
        // check if we can render module based on ALL THE buckets
        return limitTraffic.some((buckets, index) => buckets.includes(getBeaconBucket(index)));
    }
    // unknown field type, skip!
    return false;
}
