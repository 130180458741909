import { __awaiter } from "tslib";
import { getToken } from '../store/appState';
import log from './log';
export function saveAction(name, type, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        if (typeof name === 'string' && name.length) {
            try {
                yield window.SilverSurferSDK.addAction(getToken(), name, type, payload);
                log.debug('saveAction', { name, type, payload });
                return true;
            }
            catch (e) {
                log.error('saveAction exception', e, { name, type, payload });
            }
        }
        return false;
    });
}
export function getAllActions(name, type) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            // get all actions
            const actions = yield window.SilverSurferSDK.getActions(getToken(), type);
            // filter by name
            return actions.filter((a) => a.name === name);
        }
        catch (e) {
            log.error('getAllActions exception', e, { name, type });
        }
        return [];
    });
}
export function getLastAction(name, type) {
    return __awaiter(this, void 0, void 0, function* () {
        const allActions = yield getAllActions(name, type);
        const [lastAction] = allActions;
        return lastAction;
    });
}
export function saveImpressionAction(name, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        yield saveAction(name, window.SilverSurferSDK.ACTION.IMPRESSION, payload);
    });
}
export function getAllImpressions(name) {
    return __awaiter(this, void 0, void 0, function* () {
        const actions = yield getAllActions(name, window.SilverSurferSDK.ACTION.IMPRESSION);
        // just get timestamps
        return actions.map((a) => a.time);
    });
}
export const DAY_PERIOD = 24 * 60 * 60 * 1000; // 24h in ms
export const WEEK_PERIOD = 7 * DAY_PERIOD; // 7d in ms
