import { insertModule } from 'pathfinder/rules-engine/insertion';
import log from 'pathfinder/utils/log';
export default function callInsertModule(module) {
    const logPrefix = `RulesEngine/callInsertModule:`;
    try {
        if (typeof (module === null || module === void 0 ? void 0 : module.render) === 'function') {
            log.debug(`${logPrefix} Rendering module`, module);
            insertModule(module);
        }
    }
    catch (e) {
        log.error(`${logPrefix} Exception`, e);
    }
}
