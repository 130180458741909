import { __awaiter } from "tslib";
export const checkDateRangeRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { start, end } = rule;
    const currentTime = Date.now();
    let result = true;
    // if there's start date, check it
    if (typeof start === 'number' && currentTime < start) {
        result && (result = false);
    }
    // if there's end date, check it
    if (typeof end === 'number' && currentTime > end) {
        result && (result = false);
    }
    return result;
});
export const validateDateRangeRule = (rule) => {
    const { start, end } = rule;
    // value needs to be a non-empty string (if present)
    if (start && typeof start !== 'number') {
        return false;
    }
    // value needs to be a non-empty string (if present)
    if (end && typeof end !== 'number') {
        return false;
    }
    // if both are present, let's make sure end is bigger
    if (start && end) {
        return start <= end;
    }
    return true;
};
