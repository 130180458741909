import { __awaiter } from "tslib";
import { getSponsoredWidgetsByDeviceMetadata, getSponsoredWidgetsByWidgetId, } from 'pathfinder/api/sponsoredWidgets/sponsoredWidget';
import { getMockedCountdownClocks } from 'pathfinder/api/sponsoredWidgets/countdownClock';
import { chooseSponsoredWidget } from 'pathfinder/utils/sponsoredWidgets/chooseSponsoredWidget';
import { chooseSponsoredWidgetState } from 'pathfinder/utils/sponsoredWidgets/chooseSponsoredWidgetState';
import { chooseImage } from 'pathfinder/utils/sponsoredWidgets/chooseImage';
import { variantToImageSize } from './variantToImageSize';
export function getCountdownClocks(isForced) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const widgetType = 'countdown-clock';
        if (isForced) {
            return getMockedCountdownClocks();
        }
        const countdownClocks = (_a = (yield getSponsoredWidgetsByWidgetId(widgetType))) !== null && _a !== void 0 ? _a : (yield getSponsoredWidgetsByDeviceMetadata(widgetType));
        if (!countdownClocks || !countdownClocks.length) {
            return null;
        }
        return countdownClocks;
    });
}
export function getCountdownClockBaseData(countdownClocks, isForced, variant, device) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const widgetType = 'countdown-clock';
        const size = variantToImageSize(variant);
        const chosenCountdownClock = isForced
            ? countdownClocks[0]
            : chooseSponsoredWidget(countdownClocks, device, widgetType);
        if (!chosenCountdownClock) {
            return null;
        }
        const chosenCountdownClockState = chooseSponsoredWidgetState((_a = chosenCountdownClock.states) !== null && _a !== void 0 ? _a : [], device, widgetType, chosenCountdownClock === null || chosenCountdownClock === void 0 ? void 0 : chosenCountdownClock.id);
        if (!chosenCountdownClockState) {
            return null;
        }
        const image = chooseImage((_b = chosenCountdownClockState.images) !== null && _b !== void 0 ? _b : [], size, widgetType, chosenCountdownClock.id, chosenCountdownClockState.id);
        if (!image) {
            return null;
        }
        return Object.assign(Object.assign({ fontUrls: [] }, chosenCountdownClockState), { countdownClockId: chosenCountdownClock.id, countdownEndDate: new Date(chosenCountdownClock.countdownEndDateTime), image });
    });
}
