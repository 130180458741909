import _op from '../targetingRules/_operators';
import { USER_LOGGED_IN_RULE } from '../targetingRules/_templates';
import { TargetingType, ModuleType, InsertType } from '../types';
export var QuizMetaEditorVariant;
(function (QuizMetaEditorVariant) {
    QuizMetaEditorVariant["Discussions"] = "discussions";
    QuizMetaEditorVariant["Trivia"] = "trivia";
})(QuizMetaEditorVariant || (QuizMetaEditorVariant = {}));
const quizMetaEditorModules = [
    {
        name: 'discussions-quiz-targeting-editor-module',
        type: ModuleType.QuizMetaEditorModule,
        insertConfig: { type: InsertType.Custom },
        config: {
            variant: QuizMetaEditorVariant.Discussions,
        },
        targeting: _op.all(USER_LOGGED_IN_RULE, {
            type: TargetingType.CurrentContext,
            property: 'product',
            value: 'dis',
        }, _op.any({
            type: TargetingType.CurrentContext,
            property: 'productId',
            value: '2750727', // trivia.fandom.com
        }, {
            type: TargetingType.CurrentContext,
            property: 'productId',
            value: '1081891', // keiko-test.fandom.com, for testing
        }), _op.any({
            type: TargetingType.CurrentContext,
            property: 'page',
            value: 'feed',
        }, {
            type: TargetingType.CurrentContext,
            property: 'page',
            value: 'post',
        })),
    },
    {
        name: 'trivia-quiz-targeting-editor-module',
        type: ModuleType.QuizMetaEditorModule,
        insertConfig: { type: InsertType.Custom },
        config: {
            variant: QuizMetaEditorVariant.Trivia,
        },
        targeting: _op.all(USER_LOGGED_IN_RULE, {
            type: TargetingType.CurrentContext,
            property: 'product',
            value: 'trivia',
        }, {
            type: TargetingType.CurrentContext,
            property: 'page',
            value: 'quiz-page',
        }),
    },
];
export default quizMetaEditorModules;
