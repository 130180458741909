import log from 'pathfinder/utils/log';
export default function callCleanupModule(module) {
    const logPrefix = `RulesEngine/callCleanupModule:`;
    try {
        if (typeof (module === null || module === void 0 ? void 0 : module.cleanup) === 'function') {
            log.debug(`${logPrefix} Calling cleanup`, module);
            module.cleanup();
        }
    }
    catch (e) {
        log.error(`${logPrefix} Exception`, e);
    }
}
