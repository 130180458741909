import { checkUCPTargetingsCoverage } from 'pathfinder/utils/sponsoredWidgets/checkUCPTargetingsCoverage';
export function checkTargetingsCoverage(sponsoredWidget, device) {
    switch (device.pageContext.p) {
        case 'mw':
            return checkUCPTargetingsCoverage(sponsoredWidget.ucpTargetings, device);
        case 'f2':
            return checkUCPTargetingsCoverage(sponsoredWidget.ucpTargetings, device);
        default:
            return 'not supported product';
    }
}
