import { __awaiter } from "tslib";
import { triggerDpadAlert } from 'fandom-frontend-ready/alerts-metrics/alert';
import { PF_EVENTS } from 'fandom-frontend-ready/timing/registry';
import record from 'fandom-frontend-ready/timing/timings';
import log from 'pathfinder/utils/log';
import waitForSilverSurfer from 'sdk/sdk-loader';
import { IS_NOT_PRODUCTION, SS_VERSION } from 'utils/env';
import runLater from 'utils/runLater';
import { getEnabledModules } from './rules-engine/rulesEngine';
import { validateAllAvailableModules } from './rules-engine/validateModules';
import { clearRenderModules, getRenderedModules, saveSilverSurferToken, updateRenderedModules } from './store/appState';
import * as tracking from './utils/tracking';
import extractModuleNames from './rules-engine/helpers/extractModuleNames';
import callInsertModule from './rules-engine/helpers/callInsertModule';
import callCleanupModule from './rules-engine/helpers/callCleanupModule';
import { pushCurrentQuizQuestionDataToWindow } from 'pathfinder/modules/shared/QuizConsumption/utils/pushCurrentQuizQuestionDataToWindow';
import { pushQuizDataToWindow } from 'pathfinder/modules/shared/QuizConsumption/utils/pushQuizDataToWindow';
import { initialisePageHaveQuizBasingOnState } from 'pathfinder/modules/shared/QuizConsumption/utils/pushPageHaveQuizToWindow';
import { getShareState } from 'pathfinder/utils/shareState/getShareState';
// Cleanup all rendered modules
function cleanupModules() {
    // destroy rendered modules
    const modules = getRenderedModules();
    log.debug('Cleaning up previously rendered modules', extractModuleNames(modules));
    modules.forEach(callCleanupModule);
    clearRenderModules();
}
// Select and render new modules
function loadEnabledModules() {
    return __awaiter(this, void 0, void 0, function* () {
        const modules = yield getEnabledModules();
        // render all the modules
        modules.forEach(callInsertModule);
        log.info('Rendered modules', extractModuleNames(modules));
        updateRenderedModules(modules);
    });
}
// need to wrap await calls in async function
function pathFinderApp() {
    return __awaiter(this, void 0, void 0, function* () {
        log.debug('Loaded');
        record(PF_EVENTS.PF_LOADED);
        // do not change, this is supposed to be removed by tree shaking
        if (IS_NOT_PRODUCTION) {
            validateAllAvailableModules();
        }
        try {
            yield loadEnabledModules();
            window.pathfinder = {
                getState: getShareState,
            };
            tracking.trackRootPathfinder({
                action: 'viewable',
                label: 'true',
            });
            window.pathfinderModulesReady = true;
            record(PF_EVENTS.PF_READY);
            window.dispatchEvent(new CustomEvent('PathfinderModulesLoaded'));
            // We want to window variables for quizzess to be available from the beginning in order to avoid errors on GTM level
            pushQuizDataToWindow(undefined);
            pushCurrentQuizQuestionDataToWindow(undefined);
            initialisePageHaveQuizBasingOnState();
        }
        catch (e) {
            log.error('Cannot render modules', e);
            tracking.trackRootPathfinder({
                action: 'viewable',
                label: 'false',
            });
        }
    });
}
// need to wrap await calls in async function
function pathFinderAppPreload() {
    return __awaiter(this, void 0, void 0, function* () {
        log.debug(`Booting Pathfinder #${SS_VERSION}`);
        const token = yield window.SilverSurferSDK.registerClient({
            name: 'pathfinder',
            onReboot: () => {
                cleanupModules();
                runLater(loadEnabledModules);
            },
        });
        window.pathfinderTracking = tracking;
        // if we registered with the SilverSurfer
        if (token) {
            saveSilverSurferToken(token);
            log.debug('Preloaded with token', token);
            // Once we finished preloading, give browser a little bit of breathing space and then proceeed with pathfinder
            runLater(pathFinderApp);
        }
        else {
            log.error("Couldn't register with SilverSurfer, disabling Pathfinder");
            triggerDpadAlert('pf-failed-register-sdk');
        }
    });
}
// wait till SilverSurferSDK is loaded and run pathfinder's preload
waitForSilverSurfer().then(pathFinderAppPreload);
record(PF_EVENTS.PF_START);
