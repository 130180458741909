import log from '../utils/log';
import { getAllRawModules } from './config';
import { validateTargeting } from './targetingEngine';
import { ALLOWED_BUCKETS_STRING } from './testVariantsLogic';
import { InsertElementMethod, InsertType, ModuleType } from './types';
/** Validate all the `ModuleConfig`s in Pathfinder */
export function validateAllAvailableModules() {
    const allModuleConfigs = getAllRawModules();
    let errorsFound = 0;
    log.info('RulesEngine: Validating ALL the modules that are present in the code');
    console.groupCollapsed('RulesEngine: Expand module validation errors');
    // validate config
    allModuleConfigs.forEach((config) => {
        errorsFound += validateSingleModule(config);
    });
    // validate targeting
    errorsFound += validateTargeting(allModuleConfigs);
    console.groupEnd();
    if (errorsFound > 0) {
        log.error(`RulesEngine: Validation finished for ${allModuleConfigs.length} modules, found ${errorsFound} errors`);
    }
    else {
        log.info(`RulesEngine: Validation finished for ${allModuleConfigs.length} modules, no errors found`);
    }
}
function validateSingleModule(config) {
    let errorsFound = 0;
    const logPrefix = `RulesEngine/validation: ${config.name}`;
    // list of keys that have been validated (to check if entire schema is validated or not)
    const validatedKeys = [
        // internal RE keys, those will never be validated
        'internalState',
    ];
    // check validity of all the fields in order of type declaration
    // disabled ?: boolean;
    validatedKeys.push('disabled');
    if (config === null || config === void 0 ? void 0 : config.disabled) {
        const { disabled } = config;
        if (typeof disabled !== 'boolean') {
            log.error(`${logPrefix} - 'disabled' not boolean`);
            errorsFound++;
        }
    }
    // limitTraffic ?: number | string | Array<string>;
    validatedKeys.push('limitTraffic');
    if (config === null || config === void 0 ? void 0 : config.limitTraffic) {
        const { limitTraffic } = config;
        if (typeof limitTraffic === 'number') {
            if (limitTraffic <= 0) {
                log.error(`${logPrefix} - 'limitTraffic' <= 0 disables the module`);
                errorsFound++;
            }
            if (limitTraffic > 1) {
                log.error(`${logPrefix} - 'limitTraffic' > 1 always enables the module`);
                errorsFound++;
            }
        }
        else if (typeof limitTraffic === 'string') {
            if (!ALLOWED_BUCKETS_STRING.test(limitTraffic)) {
                log.error(`${logPrefix} - 'limitTraffic' has incorrect buckets`);
                errorsFound++;
            }
        }
        else if (Array.isArray(limitTraffic)) {
            if (limitTraffic.some((b) => !ALLOWED_BUCKETS_STRING.test(b))) {
                log.error(`${logPrefix} - 'limitTraffic' has incorrect buckets`);
                errorsFound++;
            }
        }
        else {
            log.error(`${logPrefix} - 'limitTraffic' is malformed`);
            errorsFound++;
        }
    }
    // name: string;
    validatedKeys.push('name');
    if (!(config === null || config === void 0 ? void 0 : config.name)) {
        log.error(`${logPrefix} - 'name' is missing`);
        errorsFound++;
    }
    else {
        const { name } = config;
        if (!/^[a-z0-9_-]+$/.test(name)) {
            log.error(`${logPrefix} - 'name' can only use lower case letters, nuumbers, dash, and underscore`);
            errorsFound++;
        }
    }
    // impressionName ?: string;
    validatedKeys.push('impressionName');
    if (config === null || config === void 0 ? void 0 : config.impressionName) {
        const { impressionName } = config;
        if (typeof impressionName !== 'string') {
            log.error(`${logPrefix} - 'impressionName' not string`);
            errorsFound++;
        }
    }
    // type: ModuleType;
    validatedKeys.push('type');
    if (!(config === null || config === void 0 ? void 0 : config.type)) {
        log.error(`${logPrefix} - 'type' is missing`);
        errorsFound++;
    }
    else {
        const { type } = config;
        if (!Object.values(ModuleType).includes(type)) {
            log.error(`${logPrefix} - 'type' unknown`);
            errorsFound++;
        }
    }
    // buckets ?: string[]
    validatedKeys.push('buckets');
    if (config === null || config === void 0 ? void 0 : config.buckets) {
        // buckets is optional, but if it exists, it must be an array of strings
        const { buckets } = config;
        if (Array.isArray(buckets)) {
            if (buckets.some((b) => !ALLOWED_BUCKETS_STRING.test(b))) {
                log.error(`${logPrefix} - incorrect 'buckets'`, buckets);
                errorsFound++;
            }
            // custom: disabled tests ideally shouldn't have defined buckets
            if ((config === null || config === void 0 ? void 0 : config.disabled) && buckets.length > 0) {
                log.info(`${logPrefix} - config still has buckets, but it is disabled; this can cause confusion, consider removing those buckets`);
            }
        }
        else {
            log.error(`${logPrefix} - 'buckets' is not an array`, buckets);
            errorsFound++;
        }
    }
    // insertConfig: InsertConfig;
    validatedKeys.push('insertConfig');
    if (!(config === null || config === void 0 ? void 0 : config.insertConfig)) {
        log.error(`${logPrefix} - 'insertConfig' is missing`);
        errorsFound++;
    }
    else {
        if (!Object.values(InsertType).includes(config.insertConfig.type)) {
            log.error(`${logPrefix} - 'insertConfig.type' unknown`, config.insertConfig.type);
            errorsFound++;
        }
        switch (config.insertConfig.type) {
            case InsertType.Element: {
                const { selectors } = config.insertConfig;
                if (!(Array.isArray(selectors) && selectors.length)) {
                    log.error(`${logPrefix} - 'insertConfig.selectors' is not an non-empty array`);
                    errorsFound++;
                }
                else if (selectors.some(({ selector, method }) => typeof selector !== 'string' || !Object.values(InsertElementMethod).includes(method))) {
                    log.error(`${logPrefix} - incorrect 'insertConfig.selectors'`, selectors);
                    errorsFound++;
                }
                break;
            }
            case InsertType.UcpInContext: {
                const { characters } = config.insertConfig;
                if (characters && !(typeof characters === 'number' && characters >= 0)) {
                    log.error(`${logPrefix} - 'insertConfig.characters' is not a positive number`, characters);
                    errorsFound++;
                }
                break;
            }
        }
    }
    // config ?: T;
    validatedKeys.push('config');
    // not verify-able
    // priority: integer;
    validatedKeys.push('priority');
    if (config === null || config === void 0 ? void 0 : config.priority) {
        if (typeof config.priority === 'number' && !isNaN(config.priority)) {
            if (!Number.isInteger(config.priority)) {
                log.error(`${logPrefix} - 'priority' cannot be a float, integer only`);
            }
        }
        else {
            log.error(`${logPrefix} - 'priority' can only be an integer, not a float, string, boolean, array, etc`);
        }
    }
    // tested separately!
    // targeting: TargetingRuleSet;
    validatedKeys.push('targeting');
    // check if there are fields that weren't validated
    const keys = Object.keys(config);
    const missingValidate = keys.filter((k) => !validatedKeys.includes(k));
    if (missingValidate.length) {
        log.error(`${logPrefix} - Found fields that couldn't be validated :`, missingValidate);
    }
    // end!
    return errorsFound;
}
