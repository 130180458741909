import { getRenderedModules } from 'pathfinder/store/appState';
import { TargetingType } from 'pathfinder/rules-engine/types';
const DEV_MODULES = ['debug-menu'];
function isDateRangeTargetingRule(ruleEngine) {
    var _a;
    return ((_a = ruleEngine) === null || _a === void 0 ? void 0 : _a.type) === TargetingType.DateRange;
}
// This function didn't verify TargetingOperator which can modify TargetingType.DateRange rule
const findModuleEndDate = (module) => {
    const dateRangeTargetingRule = module.config.targeting.find(isDateRangeTargetingRule);
    if (!dateRangeTargetingRule) {
        return undefined;
    }
    return dateRangeTargetingRule.end;
};
const omitDevModules = (module) => !DEV_MODULES.includes(module.config.name);
export const getUserActiveModules = () => getRenderedModules()
    .filter(omitDevModules)
    .map((module) => {
    var _a, _b;
    const endDate = findModuleEndDate(module);
    const result = {
        name: module.config.name,
        isPermanent: !endDate && !((_a = module.config.buckets) === null || _a === void 0 ? void 0 : _a.length) && !((_b = module.config.internalState) === null || _b === void 0 ? void 0 : _b.forced),
    };
    if (endDate) {
        result.endDate = endDate;
    }
    return result;
});
