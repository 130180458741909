import { __awaiter } from "tslib";
import log from 'pathfinder/utils/log';
export var Screen;
(function (Screen) {
    Screen["Height"] = "Height";
    Screen["Width"] = "Width";
})(Screen || (Screen = {}));
const SCREEN_PROP = {
    [Screen.Width]: 'innerWidth',
    [Screen.Height]: 'innerHeight',
};
export const checkScreenSizeRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { property, min, max } = rule;
    try {
        if (typeof min === 'undefined' && typeof max === 'undefined') {
            log.error('RuleEngine/checkScreenSizeRule: Neither min nor max value was defined', rule);
        }
        else {
            const screenProperty = window[SCREEN_PROP[property]];
            let result = true;
            if (typeof min === 'number') {
                result && (result = screenProperty >= min);
            }
            if (typeof max === 'number') {
                result && (result = screenProperty <= min);
            }
            return result;
        }
    }
    catch (e) {
        log.error('RuleEngine/checkScreenSizeRule: Exception', e, rule);
    }
    return false;
});
export const validateScreenSizeRule = (rule) => {
    const { property, min, max } = rule;
    // property needs to be correct
    if (!Object.values(Screen).includes(property)) {
        return false;
    }
    // min needs to be a positive value (if present)
    if (min && (typeof min !== 'number' || min < 0)) {
        return false;
    }
    // max needs to be a positive value (if present)
    if (max && (typeof max !== 'number' || max < 0)) {
        return false;
    }
    // if both present, max >= min
    if (max && min) {
        return max <= min;
    }
    // if none present, it's a bug
    if (!max && !min) {
        return false;
    }
    return true;
};
