import { __awaiter } from "tslib";
import log from '../../utils/log';
export const checkDomNodeRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { selector, attribute, value } = rule;
    const node = document.querySelector(selector);
    // If the node can't be found from the passed in selector, then we're done here.
    // Return false.
    if (!node) {
        return false;
    }
    // If we have the attribute param passed in, but the attribute is NOT present on the derived node, then we're done here.
    // Return false
    if (attribute && !(node === null || node === void 0 ? void 0 : node.hasAttribute(attribute))) {
        return false;
    }
    // this allows searching if an attr exist and has x value, but also accepts if attribute exists, but don't care about value
    if (attribute && (value === null || value === void 0 ? void 0 : value.length) > 0) {
        try {
            const re = new RegExp(value);
            return re.test(node.getAttribute(attribute));
        }
        catch (e) {
            log.error('RulesEngine/domNode: Exception', e, rule);
            return false;
        }
    }
    return true;
});
export const validateDomNodeRule = (rule) => {
    const { selector, attribute, value } = rule;
    // selector needs to be a non-empty string
    if (typeof selector !== 'string' || !selector.length) {
        return false;
    }
    // attribute needs to be a non-empty string
    if (attribute && (typeof attribute !== 'string' || !attribute.length)) {
        return false;
    }
    // value needs to be a non-empty string (if present)
    if (value && (typeof value !== 'string' || !value.length)) {
        return false;
    }
    return true;
};
