// implementation of tracking to support DW alerting
import trackerFactoryDataLayer, { prefixIfDev } from '../tracking/index';
const dwAlertTracker = trackerFactoryDataLayer({
    event: 'browser-client-alerting',
});
// If this event is ever fired, it should trigger a slack alert
export default function triggerAlert(alertName, slackChannel) {
    return dwAlertTracker({
        category: prefixIfDev(`browser-client-alerting-${alertName}`),
        label: slackChannel,
        action: 'alert',
    });
}
export function triggerDpadAlert(alertName) {
    return triggerAlert(alertName, 'pie-dpad-tech');
}
