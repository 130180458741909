import log from 'pathfinder/utils/log';
export function chooseImage(images, size, widgetType, widgetId, stateId) {
    const applicableImage = images
        .sort((a, b) => a.id - b.id)
        .find((value) => value.size.width === size.width && value.size.height === size.height);
    if (applicableImage) {
        return applicableImage;
    }
    log.debug(`${widgetType}: widget(${widgetId}): state(${stateId}): no applicable images`);
    return null;
}
