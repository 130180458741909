import { __awaiter } from "tslib";
import { getCookieByName } from 'utils/getCookieByName';
export const checkCookieRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    const { cookieName, value } = rule;
    const cookie = getCookieByName(cookieName);
    if (!cookie) {
        return false;
    }
    if (Array.isArray(value)) {
        return value.some((v) => cookie.includes(v));
    }
    // this allows searching if a cookie exist and has x value, but also accepts if cookie exists, but don't care about value
    return (value === null || value === void 0 ? void 0 : value.length) > 0 ? cookie.includes(value) : true;
});
export const validateCookieRule = (rule) => {
    const { cookieName, value } = rule;
    // cookieName needs to be a non-empty string
    if (typeof cookieName !== 'string' || !cookieName.length) {
        return false;
    }
    // value needs to be a non-empty string or array (if present)
    if (value && !value.length) {
        return false;
    }
    return true;
};
