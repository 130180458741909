export const pushQuizDataToWindow = (quizData) => {
    window.getQuizMetadata = () => {
        return quizData
            ? {
                quizId: quizData.id,
                quizTitle: quizData.title,
                isGenai: quizData.isGenai,
            }
            : undefined;
    };
};
