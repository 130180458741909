import { __awaiter } from "tslib";
import { fetchFromFandomService, getSponsoredWidgetService } from './sponsoredWidgetsService';
import { getSponsoredWidgetQueryId } from 'pathfinder/utils/sponsoredWidgets/getSponsoredWidgetQueryId';
export function getSponsoredWidgetsByWidgetId(widgetType) {
    return __awaiter(this, void 0, void 0, function* () {
        const widgetId = getSponsoredWidgetQueryId(widgetType);
        if (!widgetId) {
            return null;
        }
        const widgetsData = yield fetchFromFandomService(widgetType, `campaign-provider/v2/widgets/${widgetId}`);
        try {
            return widgetsData ? (yield JSON.parse(widgetsData)) : null;
        }
        catch (e) {
            return null;
        }
    });
}
export function getSponsoredWidgetsByDeviceMetadata(widgetType) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const sponsoredWidgetsService = getSponsoredWidgetService();
        const widgets = (_a = (yield sponsoredWidgetsService.getWidgets())) === null || _a === void 0 ? void 0 : _a.filter((value) => value.widgetType === widgetType);
        return widgets;
    });
}
