import { InsertElementMethod, InsertType } from './types';
import log from '../utils/log';
import { saveImpressionAction } from '../utils/actionsHelper';
import { trackExperimentsModule } from 'pathfinder/utils/tracking';
/**
 * Try to insert element in content - find the best slot for it
 */
function insertInContent(element, inContentCharacters) {
    var _a;
    // find potential places where we can insert it, count the number of characters
    let charCountSum = 0;
    const potentialSlots = Array.from(document.querySelectorAll('#mw-content-text p'))
        .filter((node) => !node.classList.contains('caption'))
        .map((node) => {
        charCountSum += node.textContent.replace(/\s/g, '').length;
        return { node, charCountSum };
    });
    // grab first slot that is after the required number of characters
    let index = potentialSlots.findIndex(({ charCountSum }) => charCountSum > inContentCharacters) - 1;
    let targetSlot;
    // now let's check if that's a good slot - the element before that one is also a paragraph
    while (index > 0 && index < potentialSlots.length) {
        // check the node
        const { node } = potentialSlots[index];
        if (((_a = node === null || node === void 0 ? void 0 : node.previousElementSibling) === null || _a === void 0 ? void 0 : _a.tagName) === 'P') {
            // we found it, great!
            targetSlot = node;
            break;
        }
        // we didn't found a good element, let's go to the next one
        index++;
    }
    if (typeof targetSlot !== 'undefined') {
        // we found the slot - insert element before it
        targetSlot.before(element);
    }
    else {
        // backup slot - at the end of the article
        document.querySelector('#mw-content-text').appendChild(element);
    }
}
export function insertModule(module) {
    var _a, _b, _c;
    const config = module.config;
    const html = module.render();
    const impressionName = (_b = (_a = module.config) === null || _a === void 0 ? void 0 : _a.impressionName) !== null && _b !== void 0 ? _b : module.config.type;
    // Trigger a generic inserted action
    trackExperimentsModule(config, { nonInteractive: true, action: 'inserted' });
    const showModuleFunc = () => {
        // log
        log.debug('RulesEngine/Insert: Module shown', config);
        if (typeof module.show === 'function') {
            module.show();
        }
        saveImpressionAction(impressionName);
        // Trigger a generic loaded action
        trackExperimentsModule(config, { nonInteractive: true, action: 'loaded' });
    };
    // non-custom inserts actually need to return an element
    if (!(html instanceof Element)) {
        if (module.config.insertConfig.type === InsertType.Custom) {
            // custom insert - do nothing, just call the show function
            log.debug('RulesEngine/Insert: Custom module, skipping inserting', config);
            showModuleFunc();
        }
        else {
            log.error('RulesEngine/Insert: Module.render did not return HTML', config);
        }
        return;
    }
    log.debug('RulesEngine/Insert: Rendering and inserting hidden module', config);
    switch (config.insertConfig.type) {
        case InsertType.Instant: {
            document.querySelector('body').appendChild(html);
            showModuleFunc();
            return;
        }
        case InsertType.Element: {
            // use hard casting
            const selectors = config.insertConfig.selectors;
            let anchor;
            let method = InsertElementMethod.Append;
            for (const value of selectors) {
                anchor = document.querySelector(value.selector);
                if (anchor) {
                    method = value.method;
                    break;
                }
            }
            if (anchor) {
                switch (method) {
                    case InsertElementMethod.After: {
                        anchor.after(html);
                        break;
                    }
                    case InsertElementMethod.Append: {
                        anchor.append(html);
                        break;
                    }
                    case InsertElementMethod.Before: {
                        anchor.before(html);
                        break;
                    }
                    case InsertElementMethod.Prepend: {
                        anchor.prepend(html);
                        break;
                    }
                    default: {
                        log.error('RulesEngine/Insert: Unknown method', method, config);
                        return;
                    }
                }
                showModuleFunc();
            }
            else {
                log.error('RulesEngine/Insert: Could not find an element to attach', config);
            }
            return;
        }
        case InsertType.UcpInContext: {
            const characters = (_c = config.insertConfig.characters) !== null && _c !== void 0 ? _c : 0;
            insertInContent(html, characters);
            showModuleFunc();
            return;
        }
    }
}
