const state = {
    silverSurferToken: null,
    allModules: [],
    renderedModules: [],
};
export const clearRenderModules = () => {
    state.renderedModules = [];
};
export const updateRenderedModules = (modules) => {
    state.renderedModules = modules;
};
export const updateAllModules = (modules) => {
    state.allModules = modules;
};
export const saveSilverSurferToken = (token) => {
    state.silverSurferToken = token;
};
export const getToken = () => state.silverSurferToken;
export const getRenderedModules = () => state.renderedModules;
export const getAllModules = () => state.allModules;
