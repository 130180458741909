import { __awaiter } from "tslib";
import { getSponsoredLogos } from 'pathfinder/modules/SponsoredWidgetsSponsoredLogoConsumptionModule/helpers/getSponsoredLogoBaseData';
export const getSponsoredWidgetsSponsoredLogoConsumptionModule = (moduleConfig) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const sponsoredLogos = yield getSponsoredLogos((_b = (_a = moduleConfig === null || moduleConfig === void 0 ? void 0 : moduleConfig.internalState) === null || _a === void 0 ? void 0 : _a.forced) !== null && _b !== void 0 ? _b : false);
    if (!sponsoredLogos || !sponsoredLogos.length) {
        return;
    }
    const module = yield import(
    /* webpackChunkName: "SponsoredWidgetsCountdownClockConsumptionModule" */ './getSponsoredWidgetsSponsoredLogoConsumptionModuleMethods');
    return module.getSponsoredWidgetsSponsoredLogoConsumptionModuleMethods(Object.assign(Object.assign({}, moduleConfig), { config: Object.assign(Object.assign({}, moduleConfig.config), { sponsoredLogos }) }));
});
