import _operators from 'pathfinder/rules-engine/targetingRules/_operators';
import { ARTICLES_RULE, DESKTOP_RULE, DISCUSSION_RULE, F2_RULE, FANHUB_RULE, LANGUAGE_EN_RULE, TRIVIA_RULE, } from 'pathfinder/rules-engine/targetingRules/_templates';
import { InsertType, ModuleType } from '../types';
import { HOMEPAGE_RULE, MEDIAWIKI_RULE } from './../targetingRules/_templates';
// display on en wikis (on articles and main pages)
const EN_WIKI_RULESET = _operators.all(MEDIAWIKI_RULE, LANGUAGE_EN_RULE, _operators.any(ARTICLES_RULE, HOMEPAGE_RULE));
// display on en discussions
const EN_DISCUSSION_RULESET = _operators.all(DISCUSSION_RULE, LANGUAGE_EN_RULE);
const haloEffectVerticalModules = [
    {
        name: 'halo-effect-vertical-desktop',
        type: ModuleType.HaloEffectVertical,
        insertConfig: { type: InsertType.Custom },
        targeting: _operators.all(DESKTOP_RULE, _operators.any(EN_WIKI_RULESET, EN_DISCUSSION_RULESET, F2_RULE, FANHUB_RULE, TRIVIA_RULE)),
    },
];
export default haloEffectVerticalModules;
