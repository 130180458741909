import { __awaiter } from "tslib";
import 'promise-any-polyfill';
import waitForEvent from 'utils/waitForEvent';
import log from 'pathfinder/utils/log';
const waitForUser = waitForEvent({
    eventCheck: () => { var _a; return typeof (window === null || window === void 0 ? void 0 : window._ulc) !== 'undefined' || typeof ((_a = window === null || window === void 0 ? void 0 : window.fandom) === null || _a === void 0 ? void 0 : _a.user) !== 'undefined'; },
    intervalTime: 50,
    maxWaitTime: 5000,
    onReject: () => ({ id: 0 }),
    onResolve: () => { var _a, _b; return (((_a = window === null || window === void 0 ? void 0 : window._ulc) === null || _a === void 0 ? void 0 : _a.id) ? window === null || window === void 0 ? void 0 : window._ulc : (_b = window === null || window === void 0 ? void 0 : window.fandom) === null || _b === void 0 ? void 0 : _b.user); },
});
export const checkUserLoggedInRule = (rule) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const user = yield waitForUser();
        const id = typeof user.id === 'string' ? parseInt(user.id, 10) : user.id;
        return rule.value === id > 0;
    }
    catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if ((e === null || e === void 0 ? void 0 : e.id) === 0) {
            // we threw an error because we timed out waiting for the user to be available
            log.debug('checkUserLoggedInRule: timed out waiting for user to be available, assuming anon');
        }
        else {
            log.debug('checkUserLoggedInRule: error while waiting for user to be available, assuming anon', e);
        }
    }
    return !rule.value;
});
export const validateUserLoggedInRule = (rule) => {
    const { value } = rule;
    // value needs to be a boolean
    if (typeof value !== 'boolean') {
        return false;
    }
    return true;
};
