import { BROWSER_MAP } from 'bowser';
import { TargetingType } from '../types';
import { UserDeviceType } from './userDevice';
import _op from './_operators';
export const MEDIAWIKI_RULE = {
    type: TargetingType.CurrentContext,
    property: 'product',
    value: 'mw',
};
export const DISCUSSION_RULE = {
    type: TargetingType.CurrentContext,
    property: 'product',
    value: 'dis',
};
export const TRIVIA_RULE = {
    type: TargetingType.CurrentContext,
    property: 'product',
    value: 'trivia',
};
export const FANHUB_RULE = {
    type: TargetingType.CurrentContext,
    property: 'product',
    value: 'fanhub',
};
export const F2_RULE = {
    type: TargetingType.CurrentContext,
    property: 'product',
    value: 'f2',
};
export const LANGUAGE_EN_RULE = {
    type: TargetingType.CurrentContext,
    property: 'pageLanguage',
    value: 'en',
};
export const USER_LOGGED_IN_RULE = {
    type: TargetingType.UserLoggedIn,
    value: true,
};
export const USER_ANON_RULE = {
    type: TargetingType.UserLoggedIn,
    value: false,
};
export const DESKTOP_RULE = {
    type: TargetingType.UserDevice,
    deviceType: UserDeviceType.Desktop,
};
export const MOBILE_RULE = {
    type: TargetingType.UserDevice,
    deviceType: UserDeviceType.Mobile,
};
export const ARTICLES_RULE = {
    type: TargetingType.CurrentContext,
    property: 'page',
    value: 'article',
};
export const HOMEPAGE_RULE = {
    type: TargetingType.CurrentContext,
    property: 'page',
    value: 'home',
};
export const MW_DESKTOP_SKIN_RULE = {
    type: TargetingType.MwConfig,
    property: 'skin',
    value: 'fandomdesktop',
};
export const MW_USER_WITHOUT_EDIT_ON_WIKI = {
    type: TargetingType.MwConfig,
    property: 'wgUserEditCount',
    value: 0,
};
export const MW_MOBILE_SKIN_RULE = {
    type: TargetingType.MwConfig,
    property: 'skin',
    value: 'fandommobile',
};
export const PRODUCT_SKIN = {
    type: TargetingType.CurrentContext,
    property: 'productVariant',
    value: 'fandommobile',
};
export const WITH_FEATURED_VIDEO_RULE = {
    type: TargetingType.DomNode,
    selector: 'body',
    attribute: 'class',
    value: 'has-featured-video',
};
export const CATEGORY_TV_RULE = {
    type: TargetingType.CurrentContext,
    property: 'productCategory',
    value: 'tv',
};
export const CATEGORY_GAMES_RULE = {
    type: TargetingType.CurrentContext,
    property: 'productCategory',
    value: 'games',
};
export const NAMESPACE_0_RULE = {
    type: TargetingType.DomNode,
    selector: 'body',
    attribute: 'class',
    value: 'ns-0',
};
export const ONLY_DEV_ENV_RULE = {
    type: TargetingType.TestOnlyInDev,
    value: true,
};
export const ON_CANARY_BRANCH_RULE = {
    type: TargetingType.TestOnlyOnBranch,
    value: 'canary',
};
export const IS_TEST_WIKI_RULE = {
    type: TargetingType.IsTestWiki,
    value: true,
};
export const GEO_US_RULE = { type: TargetingType.UserGeo, country: ['us'] };
export const ALWAYS_LOAD = [
    {
        type: TargetingType.AlwaysLoad,
    },
];
export const ON_DEBUG_SESSION_RULE = {
    type: TargetingType.Url,
    search: 'ss_debug=true',
};
export const FANDOM_ROOT_DOMAIN_RULE = {
    type: TargetingType.Url,
    host: 'www.fandom.com',
};
export const MW_MAP_NAMESPACE_RULE = {
    type: TargetingType.MwConfig,
    property: 'wgCanonicalNamespace',
    value: 'Map',
};
export const MW_EDITOR_RULE = {
    type: TargetingType.MwConfig,
    property: 'wgAction',
    value: ['edit', 'mapedit'],
};
export const TOPIC_PAGE_RULE = {
    type: TargetingType.CurrentContext,
    property: 'page',
    value: 'topic',
};
export const EDIT_MODE_RULE = {
    type: TargetingType.Url,
    search: '(?:action|veaction)=edit',
};
export const CHROME_RULE = {
    type: TargetingType.Browser,
    value: BROWSER_MAP.chrome,
};
export const SAFARI_RULE = {
    type: TargetingType.Browser,
    value: BROWSER_MAP.safari,
};
export const CHROME_SAFARI_RULE = {
    type: TargetingType.Browser,
    value: [BROWSER_MAP.chrome, BROWSER_MAP.safari],
};
export const ANON_MEDIAWIKI_RULESET = _op.all(MEDIAWIKI_RULE, USER_ANON_RULE);
export const DESKTOP_ANON_MEDIAWIKI_RULESET = _op.all(DESKTOP_RULE, ANON_MEDIAWIKI_RULESET);
export const MOBILE_ANON_MEDIAWIKI_RULESET = _op.all(MOBILE_RULE, ANON_MEDIAWIKI_RULESET);
export const DESKTOP_ANON_MEDIAWIKI_ARTICLE_RULESET = _op.all(DESKTOP_ANON_MEDIAWIKI_RULESET, ARTICLES_RULE);
export const MOBILE_ANON_MEDIAWIKI_ARTICLE_RULESET = _op.all(MOBILE_ANON_MEDIAWIKI_RULESET, ARTICLES_RULE);
export const WITHOUT_FEATURED_VIDEO_RULESET = _op.not(WITH_FEATURED_VIDEO_RULE);
export const US_EN_RULESET = _op.all(LANGUAGE_EN_RULE, GEO_US_RULE);
export const NOT_ON_PROD_RULESET = _op.any(ONLY_DEV_ENV_RULE, ON_CANARY_BRANCH_RULE);
export const IS_NOT_TEST_WIKI_RULESET = _op.not(IS_TEST_WIKI_RULE);
export const IS_NOT_EDITOR_RULESET = _op.not(MW_EDITOR_RULE);
export const WIKI_MAINPAGE_RULESET = _op.all(MEDIAWIKI_RULE, HOMEPAGE_RULE);
export const F2_PAGES_RULESET = _op.all(F2_RULE, _op.any(TOPIC_PAGE_RULE, HOMEPAGE_RULE));
export const NO_EDIT_MODE_RULESET = _op.not(EDIT_MODE_RULE);
export const NO_SAFARI_RULESET = _op.not(SAFARI_RULE);
