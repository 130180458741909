import { __awaiter } from "tslib";
import log from '../utils/log';
import { getAllTargetingRules, getAllValidateRules } from './config-targeting-rules';
import { TargetingOperator, TargetingType, } from './types';
/** Use supported operators */
export function applyOperator(op, a, b) {
    switch (op) {
        case TargetingOperator.And:
            return a && b;
        case TargetingOperator.Or:
            return a || b;
        case TargetingOperator.Not:
            return !a;
    }
}
/** Convert config with rule to async function that can be easily called by `Promise.allSettled` */
export function checkTargetingRules(config) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, _reject) => {
            checkRules(config)
                .then((result) => {
                if (result) {
                    resolve(config);
                }
                else {
                    resolve(undefined);
                }
            })
                .catch((e) => {
                log.error('RulesEngine: Exception when checking targeting rules', e, config);
                resolve(undefined);
            });
        });
    });
}
/** Check single set of rules */
export function checkRules(config) {
    return __awaiter(this, void 0, void 0, function* () {
        const allTargetingRules = getAllTargetingRules();
        const logPrefix = `RulesEngine/Targeting [${config.name}]:`;
        log.debug(`${logPrefix} Checking targeting rules`, config);
        const rules = config.targeting;
        const stack = [];
        // iterate through all the rules, gather all the promises in order
        log.debug(`${logPrefix} Converting rules to function calls`);
        const allRulesFunctions = rules.map((rule) => {
            // if this is a regular rule add it to the list
            if (typeof rule !== 'string') {
                // return a function that's going to call proper rule check with param
                const ruleCheckFunction = allTargetingRules === null || allTargetingRules === void 0 ? void 0 : allTargetingRules[rule.type];
                if (typeof ruleCheckFunction === 'function') {
                    return () => __awaiter(this, void 0, void 0, function* () {
                        try {
                            const ruleResult = yield ruleCheckFunction(rule);
                            return {
                                result: ruleResult,
                                rule: rule,
                            };
                        }
                        catch (e) {
                            log.error(`${logPrefix} Exception when checking rule`, e, rule);
                            return {
                                result: false,
                                rule: rule,
                            };
                        }
                    });
                }
            }
            // otherwise return something that we will ignore
            return () => __awaiter(this, void 0, void 0, function* () {
                return {
                    result: rule,
                    rule: rule,
                };
            });
        });
        if (!config.internalState) {
            config.internalState = {};
        }
        config.internalState = Object.assign(Object.assign({}, config.internalState), { targetingEvaluations: [] });
        // get all the results in proper order
        const ruleResults = yield Promise.all(allRulesFunctions.map((f) => {
            return f && f();
        }));
        // Push the rule result object into the targetingEvaluation array, for debugging purposes.
        // This gives users the ability to debug how a certain rule was evaluated (true/false).
        // In case of an operator being pushed into this array, the result would simply be OPERATOR string such as "AND", "OR", "NOT"
        config.internalState.targetingEvaluations.push(...ruleResults.slice());
        // iterate through all the rules once again (to use Operators)
        log.debug(`${logPrefix} Parsing targeting stack`, ruleResults);
        // sanity check for the first two items in rules. They shouldn't contains (and|or) operator as there is no previous value to operate on.
        if (ruleResults
            .slice(0, 2)
            .some(({ result }) => typeof result === 'string' && (result === TargetingOperator.And || result === TargetingOperator.Or))) {
            log.error(`${logPrefix} Incorrect operator order! You cannot use 'and|or' as first two items as there is no previous value to operate on`, stack);
            return false;
        }
        rules.forEach((rule, index) => {
            // if there's a set value in the `ruleResults`, add it to the stack
            if (typeof ruleResults[index].result === 'boolean') {
                log.debug(`${logPrefix} Step ${index}: Push ${ruleResults[index].result} to stack from`, rule);
                stack.push(ruleResults[index].result);
                return;
            }
            // if that's an operator, we have a special handle
            if (typeof rule === 'string') {
                if (rule === TargetingOperator.Not) {
                    // pop one value from stack, run function and put value back on the stack
                    const a = stack.pop();
                    const result = applyOperator(rule, a);
                    log.debug(`${logPrefix} Step ${index}: Using operator: ${rule} ${a} => ${result}`);
                    stack.push(result);
                }
                else {
                    // pop two values from stack, run function and put value back on the stack
                    const b = stack.pop();
                    const a = stack.pop();
                    const result = applyOperator(rule, a, b);
                    log.debug(`${logPrefix} Step ${index}: Using operator: ${a} ${rule} ${b} => ${result}`);
                    stack.push(result);
                }
                return;
            }
            // otherwise raise an error
            log.error(`${logPrefix} Unknown rule`, rule);
        });
        // check if there's only 1 value on stack, if not - there's something wrong with the rules
        if (stack.length !== 1) {
            log.error(`${logPrefix} Incorrect targeting rules! Leftover stack has ${stack.length} items!`, stack);
            return false;
        }
        // read the value from the top of the stack
        const value = stack.pop();
        // check if the stack has undefined as its last value, something went wrong while checking the rules
        if (value === undefined) {
            log.error(`${logPrefix} Incorrect targeting rules! Found 'undefined' value. Please check your targeting rules`, stack);
            return false;
        }
        log.debug(`${logPrefix} Final value`, value);
        config.internalState.targetingEvaluationsResult = value;
        return value;
    });
}
function validateOperatorOrRule(x, allowedOperators, allowedRuleTypes, ruleValidators) {
    const logPrefix = `RulesEngine/validateOperatorOrRule`;
    if (typeof x === 'string') {
        // operator found
        if (!allowedOperators.includes(x)) {
            log.error(`${logPrefix} - unknown operator`, x);
            return false;
        }
        return true;
    }
    if (typeof (x === null || x === void 0 ? void 0 : x.type) === 'string') {
        // rule found
        if (!allowedRuleTypes.includes(x.type)) {
            log.error(`${logPrefix} - unknown rule`, x);
            return false;
        }
        // validate configuration
        const ruleValidator = ruleValidators[x.type];
        if (!ruleValidator(x)) {
            log.error(`${logPrefix} - rule config is invalid`, x);
            return false;
        }
        return true;
    }
    log.error(`${logPrefix} - incorrect element`, x);
    return false;
}
/**
 * For all modules: Check if the outside structure of the ruleset is correct
 * (number of rules and operators) and inside structure (all rules need to
 * have some values that make sense)
 */
export function validateTargeting(configs) {
    let errorsFound = 0;
    const allowedOperators = Object.values(TargetingOperator);
    const allowedRuleTypes = Object.values(TargetingType);
    const ruleValidators = getAllValidateRules();
    // check all module configs
    configs.forEach((config) => {
        const logPrefix = `RulesEngine/validateTargeting: Error in "${config.name}"`;
        // check all targeting rules
        const { targeting } = config;
        // (1) check if it's an array
        if (!Array.isArray(targeting) || targeting.length < 1) {
            log.error(`${logPrefix} - not an array or not enough elements`);
            errorsFound++;
            return;
        }
        // (2) check if all items are of correct type and have correct values
        const ruleErrorsFound = targeting.reduce((sum, r) => sum + (validateOperatorOrRule(r, allowedOperators, allowedRuleTypes, ruleValidators) ? 0 : 1), 0);
        if (ruleErrorsFound > 0) {
            log.error(`${logPrefix} - one or more rules are incorrect`);
            errorsFound += ruleErrorsFound;
        }
        // (3) check how the targeting stack will behave - try to fake and reduce it to detect errors
        let stackSize = 0;
        targeting.forEach((t) => {
            // if that's a string - perform the operator, put the result back on slack
            if (typeof t === 'string') {
                const op = t;
                if (op === TargetingOperator.And || op === TargetingOperator.Or) {
                    // check if there are at least two items on stack, remove one of them
                    if (stackSize < 2) {
                        log.error(`${logPrefix} - incorrect operator on stack, ${op} requires two values`);
                    }
                    stackSize--;
                }
                if (op === TargetingOperator.Not) {
                    // check if there are at least one item on stack
                    if (stackSize < 1) {
                        log.error(`${logPrefix} - incorrect operator on stack, ${op} requires one value`);
                    }
                }
            }
            else {
                // if that's not a string, ignore it and push a single value to stack
                stackSize++;
            }
        });
        if (stackSize !== 1) {
            log.error(`${logPrefix} - incorrect stack size: ${stackSize} (should be a one)`, targeting);
        }
    });
    return errorsFound;
}
